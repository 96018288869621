import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {CustomURLEncoder} from '../../urlencoder/component';
import * as _moment from 'moment';
import 'moment/locale/es';

const moment = _moment;
moment.locale('es');

//const url = 'api_panel'
const url = 'http://68.183.111.73/api';

@Injectable({
  providedIn: 'root'
})
export class BannercmsService {

  registerForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      codigo: ['', [Validators.required]],
      empresa: ['', [Validators.required]],
      tipoTicket: ['', [Validators.required]],
      valorPuntos: ['', [Validators.required]],
      fechaQuemado: moment(),
      fechaExpiracionTicket:  moment(),
      idcliente: ['', [Validators.required]],
      rut: ['', [Validators.required]]
      
    });
  }

  getImages(anio_mes) {
    return this.httpClient.get(url+'/destacados/'+anio_mes);
  }




  setimage(anio_mes: string, data_image: FormData) {
    console.log("Data_image:"+ data_image);
    return this.httpClient.post<any>(
      url+'/destacados/'+anio_mes, data_image);

  }

  edit(data: any) {
    this.registerForm.setValue(data);
  }



}
