import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _moment from 'moment';
import 'moment/locale/es';
import { IMailingEmailElement } from '../interfaces/imailingemail-element';

import {CustomURLEncoder} from '../../urlencoder/component';
import { IEmailElement } from '../interfaces/iemail-element';

const moment = _moment;
moment.locale('es');

const PATH: string = "/admin/mailing/emails";

@Injectable({
	providedIn: 'root'
})

export class MailingEmailService {
	noticias: Array<number>;
	customers: Array<number>;

    constructor(
		protected httpClient: HttpClient
	) { }

    getMailingEmails(): Observable<IMailingEmailElement[]> {
		return this.httpClient.get<IMailingEmailElement[]>('/copecbff' + PATH);
	}

    store(data: any) {
		console.log('el valor de id email en el service es: '+data.email.idEmail);
		let activo = data.activo ? "1" : "0" ;
		this.noticias =  [];
		if (Array.isArray(data.noticias)) {
			data.noticias.forEach((value: any, key: any) => {
			  this.noticias.push(value.id);
			});
		}
		/*
		this.customers =  [];
		if (Array.isArray(data.customers)) {
			data.customers.forEach((value: any, key: any) => {
			  this.customers.push(value.id_customer);
			});
		} */
		const initDate = moment(data.fechaEnvio);
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('asunto', data.asunto)
			.set('id_email', ''+data.email.idEmail)
			.set('estado', data.estado)
			.set('idContactList', data.idContactList)
			.set('activo', activo)
			.set('tipo', data.tipo)
			.set('fecha_envio', initDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('id_noticia', this.noticias.join(','))
			//.set('id_customer', this.customers.join(','))

		return this.httpClient.post<any>(
			'/copecbff' + PATH,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

    update(data: any) {
		this.noticias =  [];
		if (Array.isArray(data.noticias)) {
			data.noticias.forEach((value: any, key: any) => {
			  this.noticias.push(value.id);
			});
		}
		/*
		this.customers =  [];
		if (Array.isArray(data.customers)) {
			data.customers.forEach((value: any, key: any) => {
			  this.customers.push(value.id_customer);
			});
		} */
		const initDate = moment(data.fechaEnvio);
		let activo = data.activo ? "1" : "0" ;
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('asunto', data.asunto)
			.set('id_email', ''+data.email.idEmail)
			.set('estado', data.estado)
			.set('idContactList', data.idContactList)
			.set('activo', activo)
			.set('tipo', data.tipo)
			.set('fecha_envio', initDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('id_noticia', this.noticias.join(','))
			//.set('id_customer', this.customers.join(','))

            return this.httpClient.put<any>(
                '/copecbff' + PATH + '/' + data.idMailingEmail,
                body.toString(),
                { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
            );
	}

	enviarCampana(id: number) {
		return this.httpClient.post<any>(
			'/copecbff' + PATH + '/campaigns/send/' + id,
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	enviarCampanaTest(id: number) {
		return this.httpClient.post<any>(
			'/copecbff' + PATH + '/campaigns/send/'+id+'/test',
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	anularCampana(id: number) {
		return this.httpClient.post<any>(
			'/copecbff' + PATH + '/campaigns/anular/'+id,
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

}