import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { DetailFormatService } from '../../shared/services/detail-format.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CreateDetailFormatComponent } from '../create-detail-format/create-detail-format.component';
import { map } from 'rxjs/operators';

export interface DetailFormatElement {
  id_formato_detalle_producto: number;
  titulo: string;
  detalle: string;
  tipo: number;
  finalidad: number;
  id_producto: number;
  estado: number;
}

export interface Type {
  id: number;
  value: string;
}

export interface Orientation {
  id: number;
  value: string;
}

@Component({
  selector: 'app-detail-format-list',
  templateUrl: './detail-format-list.component.html',
  styleUrls: ['./detail-format-list.component.scss']
})
export class DetailFormatListComponent implements OnInit {

  loading: boolean;
  dataSource = new MatTableDataSource<DetailFormatElement>();
  selection = new SelectionModel<DetailFormatElement>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public displayedColumns: string[] = [
    // 'select',
    'titulo',
    'tipo',
    'finalidad',
    'actions'
  ];

  types: Type[] = [
    {id: 1, value: 'Texto'},
    {id: 2, value: 'Link'}
  ];

  orientations: Orientation[] = [
    {id: 1, value: 'Derecha'},
    {id: 2, value: 'Izquierda'}
  ];

  constructor(
    public detailFormatService: DetailFormatService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService
  ) { this.loading = true; }

  ngOnInit() {
    this.detailFormatService.getDetailsFormat().subscribe((data: any) => {
      const array = data.map((item: { finalidad: number; tipo: number; }) => {
        console.log(item.finalidad);
        const orientation = this.orientations.find(o => o.id === item.finalidad);
        const type = this.types.find(o => o.id === item.tipo);
        return {
          type,
          orientation,
          ...item
        };
      });
      console.log(array);
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = array as DetailFormatElement[];
    }, (error) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  create() {
    this.dialog.open(CreateDetailFormatComponent, {
      width: '60%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.ngOnInit();
      }
    });
  }

  edit(row: any) {
    // console.log(row);
    const data = {
      id: row.id_formato_detalle_producto,
      title: row.titulo,
      detail: row.detalle,
      type: row.tipo,
      orientation: row.finalidad,
      id_product: row.id_producto
    };
    this.detailFormatService.edit(data);
    this.create();
  }

  delete(id: number) {
    console.log(id);
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
      if (res) {
        this.loading = true;
        this.detailFormatService.delete(id).subscribe((data: any) => {
            this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
            console.log(data);
          }, (error: any) => {
            console.error(error);
          }, () => { this.ngOnInit(); }
        );
        // console.log(id);
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: DetailFormatElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id_formato_detalle_producto + 1}`;
  }
}
