import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductService } from 'src/app/shared/services/product.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { MatDialogRef } from '@angular/material';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { CategoryService } from '../../shared/services/category.service';
import * as _ from 'lodash';
import { ExchangesTypeService } from 'src/app/shared/services/exchanges-type.service';
import { TagsService } from 'src/app/shared/services/tags.service';
import { Observable, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss']
})
export class CreateProductComponent implements OnInit, OnDestroy {

  public loading: boolean;
  public estado: boolean;
  public exchange: boolean;
  public disabled: boolean;
  public submitted: boolean;
  public selected: boolean;
  public categories: any;
  public exchanges: any;
  public tags$: Observable<any>;
  public selectedTag: any;
  public isReadonly = true;
  public ckeConfig: any;
  public subscription: Subscription;
  public idComponent: number;
  public nameComponent = 'Producto';
  public reset: boolean;

  constructor(
    public dialogRef: MatDialogRef<CreateProductComponent>,
    private notificationService: NotificationsService,
    public productService: ProductService,
    private resourcesService: ResourcesService,
    private categoryService: CategoryService,
    private exchangesTypeService: ExchangesTypeService,
    private tagService: TagsService
  ) {
    this.estado = true;
    this.exchange = this.productService.productForm.get('exchange').value;
    this.disabled = true;
    this.idComponent = this.productService.productForm.get('id_producto').value;
    this.reset = this.productService.productForm.get('id_producto').value ? true : false;
  }

  ngOnInit() {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'about', groups: [ 'about' ] },
        { name: 'styles', groups: [ 'styles' ] }
      ],
      removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteText,PasteFromWord,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Underline,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Anchor,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,TextColor,Maximize,ShowBlocks,About,Styles,Format,Font,FontSize,Scayt',
      removeDialogTabs: 'link:advanced'
    };
    // PRODUCT-CATEGORIES
    this.categoryService.getCategories().subscribe((res) => {
      this.categories = res;
    }, (error: any) => {
      console.error(error);
    }, () => {
      const category = _.find(this.categories, (o) => {
        return o.id_categoria_producto === this.productService.productForm.get('id_categoria_producto').value;
      });
      this.productService.productForm.get('id_categoria_producto').setValue(category);
    });

    // EXCHANGES-TYPE
    this.exchangesTypeService.getExchangesType().subscribe((res) => {
      this.exchanges = res;
    }, (error: any) => {
      console.error(error);
    }, () => {
      const tag = _.find(this.exchanges, (o) => o.id_tipo_canje === this.productService.productForm.get('id_tipo_canje').value);
      this.productService.productForm.get('id_tipo_canje').setValue(tag);
    });

    // TAGS
    this.tags$ = this.tagService.getTags();
    this.selectedTag = this.productService.productForm.get('tags_producto').value;
  }

  onSubmit() {
    if (this.productService.productForm.valid) {
      if (!this.productService.productForm.get('id_producto').value) {
        this.subscription = this.productService.store(this.productService.productForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Guardado satisfactoriamente', 'success');
          this.onClose(true);
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('Client-side error');
          } else {
            console.log('Server-side error');
          }
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.subscription = this.productService.update(this.productService.productForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  get f() { return this.productService.productForm.controls; }

  onFileInput(fileInput: any, $event: any) {
    $event.preventDefault();
    fileInput.click();
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    this.productService.productForm.controls.imagen.setValue(inputNode.files[0].name);

    this.resourcesService.upload(inputNode.files[0], 'banner').subscribe((data: any) => {
      console.log(data);
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  onChange($event: any) {
    this.estado = $event.checked;
  }

  onExchange($event: any) {
    this.exchange = $event.checked;
  }

  onClose(confirmation: boolean) {
    this.productService.productForm.reset(this.productService.initialValues);
    this.dialogRef.close(confirmation);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
