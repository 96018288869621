import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as _moment from 'moment';
import 'moment/locale/es';
import { IPopupElement } from '../interfaces/ipopup-element';

import {CustomURLEncoder} from '../../urlencoder/component';

const moment = _moment;
moment.locale('es');

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  registerForm: FormGroup;


  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      id_pop_up: null,
      imagen: null,
      link: null,
      activo: null

    });
  }

  getPopups(): Observable<IPopupElement[]> {
    return this.httpClient.get<IPopupElement[]>('/copecbff/admin/pop-ups');
  }

  show(id: any) {
    return this.httpClient.get('/copecbff/admin/pop-ups/' + id);
  }

  edit(data: any) {
    this.registerForm.setValue(data);
  }

  store(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('activo', (data.activo) ? '1' : '0')
      .set('imagen', data.imagen)
      .set('link', data.link);

    return this.httpClient.post<any>(
      '/copecbff/admin/pop-ups',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any): Observable<IPopupElement[]> {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('activo', data.activo)
      .set('imagen', '/resource/popup/' + data.imagen)
      .set('link', data.link);

    return this.httpClient.put<IPopupElement[]>(
      '/copecbff/admin/pop-ups/' + data.id_pop_up,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
  }

  delete(id: number) {
    return this.httpClient.delete('/copecbff/admin/pop-ups/' + id);
  }


  upload(file: any, folder: string) {
    console.log(file);
    // const params = new HttpParams().set('folder', folder);
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('folder', 'images');

    console.log(formData);
    return this.httpClient.post<any>(
      '/copecbff/admin/resources',
      formData);
  }
}
