import { Component, OnInit, OnDestroy, Inject, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { VideosService } from 'src/app/shared/services/videos.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TagsService } from 'src/app/shared/services/tags.service';

/* Interfaz para Zonas Eds */
interface ZonasEds {
valor: string;
valorVista: string;
}


@Component({
  selector: 'app-create-video',
  templateUrl: './create-videos.component.html',
  styleUrls: ['./create-videos.component.scss']
})
export class CreateVideosComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public estado: boolean;
  public submitted: boolean;
  public selected: boolean;
  public idComponent: number;
  public folder: string;
  public videoForm: FormGroup;
  public nameComponent = 'Video';
  public subscription: Subscription;
  public tags$: Observable<any>;
  public selectedTag: any;
  public initialValues : any;
  public idVimeo: string;
  public ckeConfig: any;
  public zonasEds: ZonasEds[] = [
    {valor: 'SIN-ZONA', valorVista: 'Sin Zona'},
    {valor: 'ZONA-NORTE-1', valorVista: 'Zona Norte 1'},
    {valor: 'ZONA-NORTE-2', valorVista:'Zona Norte 2'},
    {valor: 'ZONA-SANTIAGO-1', valorVista: 'Zona Santiago 1'},
    {valor: 'ZONA-SANTIAGO-2', valorVista: 'Zona Santiago 2'},
    {valor: 'ZONA-SANTIAGO-3', valorVista: 'Zona Santiago 3'},
    {valor: 'ZONA-SANTIAGO-4', valorVista: 'Zona Santiago 4'},
    {valor: 'ZONA-SANTIAGO-5', valorVista: 'Zona Santiago 5'},
    {valor: 'ZONA-SANTIAGO-6', valorVista: 'Zona Santiago 6'},
    {valor: 'ZONA-SANTIAGO-7', valorVista: 'Zona Santiago 7'},
    {valor: 'ZONA-SANTIAGO-8', valorVista: 'Zona Santiago 8'},
    {valor: 'ZONA-SANTIAGO-9', valorVista: 'Zona Santiago 9'},
    {valor: 'ZONA-SANTIAGO-10', valorVista: 'Zona Santiago 10'},
    {valor: 'ZONA-CENTRAL-1', valorVista: 'Zona Central 1'},
    {valor: 'ZONA-CENTRAL-2', valorVista: 'Zona Central 2'},
    {valor: 'ZONA-CENTRAL-3', valorVista: 'Zona Central 3'},
    {valor: 'ZONA-CENTRAL-4', valorVista: 'Zona Central 4'},
    {valor: 'ZONA-CENTRAL-5', valorVista: 'Zona Central 5'},
    {valor: 'ZONA-CENTRAL-6', valorVista: 'Zona Central 6'},
    {valor: 'ZONA-SUR-1', valorVista: 'Zona Sur 1'},
    {valor: 'ZONA-SUR-2', valorVista: 'Zona Sur 2'},
    {valor: 'ZONA-SUR-3', valorVista: 'Zona Sur 3'},
    {valor: 'ZONA-SUR-4', valorVista: 'Zona Sur 4'},
    {valor: 'ZONA-SUR-5', valorVista: 'Zona Sur 5'},
    {valor: 'ZONA-SUR-6', valorVista: 'Zona Sur 6'},
    {valor: 'ZONA-SUR-7', valorVista: 'Zona Sur 7'},
    {valor: 'ZONA-SUR-8', valorVista: 'Zona Sur 8'}
  ];
  constructor(
    public dialogRef: MatDialogRef<CreateVideosComponent>,
    public tagsService : TagsService,
    public notificationService: NotificationsService,
    public videosService: VideosService,
    public resourcesService :ResourcesService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    this.videoForm = this.formBuilder.group({
      id: null,
      titulo: ['', [Validators.required, Validators.minLength(3)]],
      url: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.pattern('[0-9]+')])],
      descripcion: ['', Validators.required],
      orden: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
      estado: [true],
      rutaImagenPrevia: null,
      responsable: null,
      zonaEds: ['', [Validators.required]],
      tags: Array()

    });
    this.estado = true;
    this.idComponent = this.videoForm.get('id').value;
    this.initialValues = this.videoForm.value;
   }

  ngOnInit() {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'about', groups: [ 'about' ] },
        { name: 'styles', groups: [ 'styles' ] }
      ],
      removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteText,PasteFromWord,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Underline,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Anchor,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,TextColor,Maximize,ShowBlocks,About',
      removeDialogTabs: 'link:advanced'
      };
    /* Asigna valores al form de un registro existente */
    if(this.data!=null){
    const videoExistente = {
      id: this.data.id,
      titulo: this.data.titulo,
      descripcion: this.data.descripcion,
      orden: this.data.orden,
      url: this.data.url,
      estado: this.data.estado,
      rutaImagenPrevia: this.data.rutaImagenPrevia,
      responsable:this.data.responsable,
      zonaEds:this.data.zonaEds,
      tags: (Array.isArray(this.data.tags)) ? this.data.tags : Array()
      }
    this.videoForm.setValue(videoExistente);
  }
  // TAGS
  this.tags$ = this.tagsService.getTags();
  this.selectedTag = this.videoForm.get('tags').value;

} // cierra ngOnInit

/*Crea el video */
  onSubmit() {
    if (this.videoForm.valid) {
      /* se valida si hay una ruta de imagen asignada al campo, de lo contrario generamos una */
        if(this.videoForm.get('rutaImagenPrevia').value==null){
          this.videoForm.controls['rutaImagenPrevia'].setValue('videocopec.jpg');
        }
        /* Obtenemos URL del video ingresado */
        this.idVimeo= this.videoForm.get('url').value;
        /* Se deja solo el numero (ID) del video en VIMEO */
        this.idVimeo= this.idVimeo.replace(/\D/g, "");
        /* Hacemos una nueva ruta para que se logre ver el video en el front */
    /*    this.videoForm.controls['url'].setValue('https://player.vimeo.com/video/' +
        this.idVimeo); */


      /* Validamos si vamos a crear  o actualizar según ID */
      if (!this.videoForm.get('id').value) {
        this.videosService.create(this.videoForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        /*acá actualiza un video */
        this.videosService.update(this.videoForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  get f() { return this.videoForm.controls; }


  onFileInput(fileInput: any, $event: any, folder: string) {
    this.folder = folder;
    $event.preventDefault();
    fileInput.click();
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
      this.videoForm.controls.rutaImagenPrevia.setValue(inputNode.files[0].name);

      this.resourcesService.upload(inputNode.files[0], this.folder).subscribe((data: any) => {
        console.log(data);
      }, (error: any) => {
        console.error(error);
      }, () => { this.loading = false; });
    }






  onChange($event: any) {
    this.estado = $event.checked;
  }

  onClose(confirmation: boolean) {
    this.videoForm.reset();
    this.dialogRef.close(confirmation);
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
