import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ILikeElement } from '../interfaces/ilike-element';
import {CustomURLEncoder} from '../../urlencoder/component';

@Injectable({
  providedIn: 'root'
})
export class LikeService {

  registerForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      id: null,
      name: ['', [Validators.required, Validators.minLength(3)]],
      imagen: ['', Validators.required],
      status: ['']
    });
  }

  getLikes(): Observable<ILikeElement[]> {
    return this.httpClient.get<ILikeElement[]>('/copecbff/admin/customer_gustos');
  }

  edit(data: any) {
    this.registerForm.setValue(data);
  }

  store(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre', data.name)
      .set('imagen', '/resource/images/' + data.imagen)
      .set('estado', data.status);

    return this.httpClient.post<ILikeElement>(
      '/copecbff/admin/customer_gustos',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre', data.name)
      .set('imagen', '/resource/images/' + data.imagen)
      .set('estado', data.status);

    return this.httpClient.put<ILikeElement[]>(
      '/copecbff/admin/customer_gustos/' + data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
  }

  delete(id: number) {
    return this.httpClient.delete('/copecbff/admin/customer_gustos/' + id);
  }
}
