import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CustomURLEncoder} from '../../urlencoder/component';

import * as _moment from 'moment';
import 'moment/locale/es';

const moment = _moment;
moment.locale('es');

//const url = 'api_panel'
const url = 'http://68.183.111.73/api';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  companyForm: FormGroup;
  tags: Array<number>;
  initialValues: any;

  constructor(
    protected httpClient: HttpClient,
    public formBuilder: FormBuilder
  ) {
    this.companyForm = this.formBuilder.group({
      id: null,
      nombre_alianza: ['', Validators.required],
      link: ['', Validators.required],
      imagen: null,
      dateCreate_at: [moment(), Validators.required],
      dateUpdate_at: [moment(), Validators.required],
      color: [moment(), Validators.required],
      //file:  ['', Validators.required]
    });
    this.initialValues = this.companyForm.value;
  }

  upload_file(id: string, file: FormData){
    ///
    if (id == null)
      id = '0';
    console.log(file);


    //const inputNode: any = document.querySelector('#file');
    return this.httpClient.post<any>(
      url+'/subir_archivo_alianza/'+id,  file);

  }

  getCompanies(id?: number, submenu: boolean = false) {
      if (!id) {
        return this.httpClient.get(url+'/empresa');
      } else {
        return this.httpClient.get(url+'/empresa/' + id);
      }
  }

  edit(data: any): void {
    this.companyForm.setValue(data);
  }

  store(data: any) {

    this.tags =  [];
    const date_upd = moment(data.dateUpdate_at);
    const date_cre = moment(data.dateCreate_at);

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre_alianza', data.nombre_alianza)
      .set('dateCreate_at', date_cre.format('YYYY-MM-DD HH:mm:ss'))
      .set('dateUpdate_at', date_upd.format('YYYY-MM-DD HH:mm:ss'))
      .set('link', data.link)
      .set('color', data.color)
      .set('imagen', data.imagen);

    return this.httpClient.post<any>(
      url+'/empresa',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any) {

    this.tags = [];
    const date_upd = moment(data.dateUpdate_at);

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre_alianza', data.nombre_alianza)
      .set('dateUpdate_at', date_upd.format('YYYY-MM-DD HH:mm:ss'))
      .set('link', data.link)
      .set('color', data.color)
      .set('imagen', data.imagen);
    return this.httpClient.put<any>(
      url+'/empresa/' + data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
  }

  delete(id: number) {
    return this.httpClient.delete(url+'/empresa/' + id);
  }
}
