import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { MatTableDataSource, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { CompanyService } from '../../shared/services/company.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CreateCompanyComponent } from '../create-company/create-company.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ICompanyElement } from 'src/app/shared/interfaces/icompany-element';

import * as _moment from 'moment';
import 'moment/locale/es';
import { JwtService } from 'src/app/shared/services/jwt.service';

const moment = _moment;
moment.locale('es');

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {

  loading: boolean;
  hidden: boolean;
  dataSource = new MatTableDataSource<ICompanyElement>();
  selection = new SelectionModel<ICompanyElement>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public displayedColumns: string[] = [
    'id',
    'nombre_alianza',
    'imagen',
    'link',
    'dateCreate_at',
    'dateUpdate_at',
    'actions'
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    public companyService: CompanyService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    private location: Location,
    protected jwtService: JwtService
  ) { this.loading = true; }

  ngOnInit(): void {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getCompanies();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getCompanies() {
    this.activeRouter.queryParams.subscribe(params => {
      this.hidden = (params.id) ? true : false;
      this.companyService.getCompanies(params.id, params.submenu).subscribe((data: any) => {
        this.dataSource.data = data as ICompanyElement[];
      }, (error: any) => {
        console.error(error);
      }, () => { this.loading = false; });
    });
  }

  create() {
    this.dialog.open(CreateCompanyComponent, {
      width: '60%',
      height: '85%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.getCompanies();
      }
    });
  }

  edit(row: any) {

    const data = {
      id: row.id,
      nombre_alianza: row.nombre_alianza,
      imagen: row.imagen,
      dateCreate_at: row.dateCreate_at,
      dateUpdate_at: row.dateUpdate_at,
      link: row.link,
      color: row.color
    };

    this.companyService.edit(data);
    this.create();
  }

  delete(id: number) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
      if (res) {
        this.loading = true;
        this.companyService.delete(id).subscribe((data: any) => {
            this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
          }, (error: any) => {
            console.error(error);
          }, () => { this.getCompanies(); }
        );
      }
    });
  }


  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ICompanyElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
}
