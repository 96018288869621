import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailService } from '../../shared/services/email.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from '../../shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { EmailsComponent } from '../emails/emails.component';
import { NotificationsService } from '../../shared/services/notifications.service';
import { IEmailElement } from '../../shared/interfaces/iemail-element';
import { JwtService } from '../../shared/services/jwt.service';
@Component({
  selector: 'app-emails-list',
  templateUrl: './emails-list.component.html',
  styleUrls: ['./emails-list.component.scss']
})
export class EmailsListComponent implements OnInit {

	public displayedColumns: string[] = [
		'nombre',
		'fecha_inicio',
		'fecha_fin',
		'tipo',
		'estado',
		'actions'
	];

	loading: boolean;
	dataSource = new MatTableDataSource<IEmailElement>();
	selection = new SelectionModel<IEmailElement>(true, []);

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

	constructor(
		private emailService: EmailService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService
	) {
		this.loading = true;
	}

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getEmails();
		} else {
			this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
		}
	}

	getEmails(): void {
		this.emailService.getEmails().subscribe(
			(data: any) => {
				this.dataSource.data = data as IEmailElement[];
			},(error) => {
				console.error(error);
			}, () => {
				this.loading = false;
			}
		);
	}

	openModal(data?: any): void {
		this.dialog.open(EmailsComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe(() => {
			this.getEmails();
		});
	} 
	
	/** Filter rows matches. */
	applyFilter(filterValue: string): void {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

}
