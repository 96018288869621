import { Component, OnInit } from '@angular/core';
import { TagsService } from 'src/app/shared/services/tags.service';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { Observable } from 'rxjs';
import { IIconElement } from 'src/app/shared/interfaces/iicon-element';
import * as _ from 'lodash';

@Component({
  selector: 'app-create-tag',
  templateUrl: './create-tag.component.html',
  styleUrls: ['./create-tag.component.scss']
})
export class CreateTagComponent implements OnInit {

  public loading: boolean;
  public estado: boolean;
  public submitted: boolean;
  public aux: any;
  public tags: any;
  public reset: boolean;
  public idComponent: number;
  public nameComponent = 'Etiqueta';

  icons: IIconElement[] = [{
      icon: 'fas fa-home fa-2x',
      name: 'HOME'
    }, {
      icon: 'fas fa-laptop fa-2x',
      name: 'LAPTOP'
    }, {
      icon: 'fas fa-star fa-2x',
      name: 'STAR'
    }, {
      icon: 'fas fa-briefcase-medical fa-2x',
      name: 'BRIEFCASE MEDICAL'
    }, {
      icon: 'fas fa-heart fa-2x',
      name: 'HEART'
    }, {
      icon: 'fas fa-taxi fa-2x',
      name: 'TAXI'
    }, {
      icon: 'fas fa-spa fa-2x',
      name: 'SPA'
    }, {
      icon: 'fas fa-tshirt fa-2x',
      name: 'T-SHIRT'
    }, {
      icon: 'fas fa-plane fa-2x',
      name: 'PLANE'
    }, {
      icon: 'fas fa-car fa-2x',
      name: 'CAR'
  }];

  constructor(
    public tagService: TagsService,
    public dialogRef: MatDialogRef<CreateTagComponent>,
    private notificationService: NotificationsService,
  ) {
    this.estado = false;
    this.idComponent = this.tagService.registerForm.get('id_tag_producto').value;
  }

  ngOnInit() {
    this.reset = this.tagService.registerForm.get('id_tag_producto').value;
    // Icons
    this.tags = this.icons;
    const icon = this.icons.find(c => c.icon === this.tagService.registerForm.get('icono').value);
    this.tagService.registerForm.get('icono').setValue(icon);

    // Tags
    // this.tagService.getIcons().subscribe((res) => {
    //   this.tags = res;
    // }, (error: any) => {
    //   console.error(error);
    // }, () => {
    //   const tag = _.find(this.tags, (o) => o.icon === this.tagService.registerForm.get('icono').value);
    //   this.tagService.registerForm.get('icono').setValue(tag);
    // });
  }

  onSubmit() {
    if (this.tagService.registerForm.valid) {
      if (!this.tagService.registerForm.get('id_tag_producto').value) {
        this.tagService.store(this.tagService.registerForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          this.notificationService.message(':: Registro guardado satisfactoriamente', 'success');
        });
      } else {
        this.tagService.update(this.tagService.registerForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          this.notificationService.message(':: Registro actualizado satisfactoriamente', 'success');
        });
      }
    }
  }

  get f() { return this.tagService.registerForm.controls; }

  onChange($event: any) {
    this.estado = $event.checked;
  }

  onClose(confirmation: boolean) {
    this.tagService.registerForm.reset();
    this.dialogRef.close(confirmation);
  }

}
