import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { NavbarService } from 'src/app/shared/services/navbar.service';
import { Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from '../../shared/services/notifications.service';
import { CreateMenuComponent } from '../create-menu/create-menu.component';
import { CreateSubmenuComponent } from '../create-submenu/create-submenu.component';
import { INavBarElement } from 'src/app/shared/interfaces/inavbar-element';
import { ISubNavBarElement } from 'src/app/shared/interfaces/isubnavbar-element';
import { InformationSubmenuComponent } from 'src/app/information/information-submenu/information-submenu.component';
import { InformationSubmenuService } from 'src/app/shared/services/information-submenu.service';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  loading: boolean;
  visibleFlag: boolean;
  visibleMenu: boolean;
  visibleSubMenu: boolean;

  dataSource = new MatTableDataSource<INavBarElement>();
  dataSourceSubMenu = new MatTableDataSource<ISubNavBarElement>();
  selection = new SelectionModel<INavBarElement>(true, []);

  public displayedColumns: string[] = [
    // 'select',
    'nombre',
    'orden',
    'estado',
    'actions'
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private router: Router,
    protected navBarService: NavbarService,
    protected infoSubMenuServ: InformationSubmenuService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getNavBars();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getNavBars() {
    this.navBarService.getNavBars().subscribe((data: any) => {
      this.dataSource.data = data as INavBarElement[];
    }, (error) => {
      console.error(error);
    }, () => {
      if (this.visibleFlag) {
        this.visibleMenu = false;
        this.visibleSubMenu = true;
      } else {
        this.visibleMenu = true;
        this.visibleSubMenu = false;
      }
      this.loading = false;
    });
  }

  create() {
    this.dialog.open(CreateMenuComponent, {
      width: '60%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.getNavBars();
      }
    });
  }

  editMenu(row: any) {
    const data = {
      id: row.id,
      nombre: row.nombre,
      strIndex: row.strIndex,
      color: row.color,
      orden: row.orden,
      tipo: row.tipo,
      link: row.link,
      visibleTop: row.visibleTop,
      visibleOnlyLogin: row.visibleOnlyLogin,
      userElement: row.userElement,
      estado: row.estado
    };
    this.navBarService.editMenu(data);
    this.create();
  }

  deleteMenu(id: any) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?')
    .afterClosed().subscribe(res => {
      if (res) {
        this.navBarService.deleteMenu(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => { this.ngOnInit(); });
      }
    });
  }

  getSubMenu(id: any) {
    this.router.navigate(['submenu'], { queryParams: { id }});
    // this.dataSourceSubMenu.data = row.submenues as ISubNavBarElement[];
    // localStorage.setItem('id_scmenu', row.id);
    // console.log(this.dataSourceSubMenu.data);
    // this.visibleMenu = true;
    // this.visibleSubMenu = false;
    // this.visibleFlag = false;
    // if (row.tipo == '2') {
    //   const idSubMenu = row.id;
    //   this.router.navigate(['submenu'], { queryParams: { id: idSubMenu }});
    // } else {
    //   this.notificationService.message(':: No contiene SubMenu', 'warn');
    // }
  }

  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: INavBarElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

}
