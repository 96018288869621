import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CustomURLEncoder} from '../../urlencoder/component';

import * as _moment from 'moment';
import 'moment/locale/es';

const moment = _moment;
moment.locale('es');

//const url = 'api_panel'
const url = 'http://68.183.111.73/api';

@Injectable({
  providedIn: 'root'
})
export class MarcaService {
  
  registerForm: FormGroup;
  tags: Array<number>;
  initialValues: any;
  


  constructor(
    protected httpClient: HttpClient,
    public formBuilder: FormBuilder,
  ) {
    this.registerForm = this.formBuilder.group({
      id: null,
      nombre_marca: ['', Validators.required],
      fecha_creacion: [moment(), Validators.required],
    });
    this.initialValues = this.registerForm.value;
  }

  getCategories(id?: number, submenu: boolean = false) {
      if (!id) {
        return this.httpClient.get(url+'/marca');
      } else {
        return this.httpClient.get(url+'/marca/' + id);
      }
  }


  getMarcas() {
        return this.httpClient.get(url+'/marca');
  }

  edit(data: any): void {
    this.registerForm.setValue(data);
  }

  store(data: any) {

    this.tags =  [];
    const date_upd = moment(data.fecha_creacion);
    
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre_marca', data.nombre_marca)
      .set('fecha_creacion', data.fecha_creacion.format('YYYY-MM-DD HH:mm:ss'))
      

    return this.httpClient.post<any>(
      url+'/marca',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any) {

    this.tags = [];
    const date_upd = moment(data.dateUpdate_at);

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('id', data.id)
      .set('nombre_marca', data.nombre_marca)
      
    return this.httpClient.put<any>(
      url+'/marca/'+data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
  }


  delete(id: number) {
    return this.httpClient.delete(url+'/marca/' + id);
  }


}
