import { OnChanges } from '@angular/core';
import { Component, OnInit, Input, forwardRef, ViewChild, ElementRef  } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotificationsService } from '../../services/notifications.service';
import { ResourcesService } from '../../services/resources.service';

@Component({
	selector: 'app-resource-input',
	templateUrl: './resource-input.component.html',
	styleUrls: ['./resource-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ResourceInputComponent),
			multi: true
		}
	]
})
export class ResourceInputComponent implements OnInit, OnChanges, ControlValueAccessor {

	private readonly base = '/resource';

	@ViewChild('fileInput', {static: true}) fileInput: ElementRef<HTMLElement>;
	@Input() folder: string;
	@Input() label: string;
	@Input() link: string;
	@Input() orden: number;
	@Input() categoria: number;
	@Input() rename: boolean;
	disabled = true;

	visibleValue: string = '';

	hiddenValue: string = '';

	onChange: any = () => {	};
	
	onTouched: any = () => { };

	constructor(private resourcesService: ResourcesService,
		private notificationsService: NotificationsService) { }

	ngOnInit(): void {
		this.validateFolder();
	}

	ngOnChanges(): void {
		this.validateFolder();
	}

	validateFolder() {
		if (!this.folder) {
			throw new Error("El componente no admite que la carpeta sea nula o esté en blanco");
		}
		/* La carpeta no puede contener caracteres prohibidos en URLs */
		const forbiddenChars = /[:\/\?#\[\]@!$&'()*+,;=]/;
		if (forbiddenChars.test(this.folder)) {
			throw new Error("El componente no admite caracteres reservados en el nombre de la carpeta donde se guardarán los recursos");
		}
		this.disabled = false;
	}

	writeValue(value: string): void {
		/* Se corrige la ruta de la imagen de imagen para cargarla al formulario */
		if (value) {
			let imagen = value.split('/');
			this.visibleValue = imagen[imagen.length - 1];
			this.hiddenValue = value;
		}
	}
	
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	uploadFile() {
		const fileInputElement: HTMLElement = this.fileInput.nativeElement;
    	fileInputElement.click();
	}

	onInput(value: string) {
		this.syncInputs(value);
	}

	onFileSelected(files: FileList) {
		const file = files[0];
		this.disabled = true;
		this.resourcesService.uploadResourceNoticia(file, this.folder, this.link, this.orden, this.categoria, this.rename).subscribe((data: any) => {
			this.notificationsService.success(':: Recurso cargado exitosamente');
			this.syncInputs(data.name);
		}, (error: any) => {
			this.notificationsService.warn(`:: Error cargando recurso: ${error.message}`);
		}).add(() => {
			this.disabled = false;
		});
	}

	private syncInputs(value: string) {
		this.visibleValue = value;
		// this.hiddenValue = `${this.base}/${this.folder}/${value}`;
		this.hiddenValue = `${value}`;
		this.onChange(this.hiddenValue);
	}
}
