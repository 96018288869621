import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { BannerService } from '../../shared/services/banner.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { BannerComponent } from '../banner/banner.component';
import { NotificationsService } from '../../shared/services/notifications.service';
import { ResourceListComponent } from '../../resources/resource-list/resource-list.component';
import { IBannerElement } from 'src/app/shared/interfaces/ibanner-element';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.scss']
})
export class BannerListComponent implements OnInit {

  public displayedColumns: string[] = [
    // 'select',
    'nombre',
    'orden',
    'ruta',
    'estado',
    'fecha_inicio',
    'fecha_fin',
    'tipo',
    'actions'
  ];

  loading: boolean;
  dataSource = new MatTableDataSource<IBannerElement>();
  selection = new SelectionModel<IBannerElement>(true, []);

  imageToShow: any;
  isImageLoading: boolean;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private bannerService: BannerService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getBanners();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
   }

  getBanners(): void {
    this.bannerService.getBanners().subscribe((data: any) => {
      this.dataSource.data = data as IBannerElement[];
    }, (error) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  create(): void {
    this.dialog.open(BannerComponent, {
      width: '60%',
      height: '85%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe(() => {
      this.getBanners();
    });
  }

  edit(row: any): void {

    const ruta = row.ruta.split('/');
    const initDate = row.fecha_inicio.split(' ');
    const endDate = row.fecha_fin.split(' ');
    var tipo= 1;
    console.log(row.html);
    console.log(row.html == undefined);
    var html = '';
    if (row.tipo == undefined){
      var tipo = 1;
    }
    else{
      var tipo = parseInt(row.tipo);
    }
    
    if (row.html == undefined){
      var html = '';
    }
    else{
      var html = String(row.html);
    }

    const data = {
      $key: row.id_banner,
      name: row.nombre,
      route: ruta[3],
      order: row.orden,
      url: row.url,
      date_init: initDate[0] + 'T' + initDate[1],
      date_end: endDate[0] + 'T' + endDate[1],
      status: (row.estado) ? 1 : 0,
      blank: (row.blank) ? 1 : 0,
      responsive: (row.responsive) ? 1 : 0,
      html: html,
      tipo: tipo,

    };
    console.log(data);
    this.bannerService.edit(data);
    this.create();
  }

  delete(id: number): void {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
      if (res) {
        this.loading = true;
        this.bannerService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => { this.getBanners(); });
      }
    });
  }

  resourceBanner(row: any) {
    this.dialog.open(ResourceListComponent, {
      width: '60%',
      autoFocus: true,
      disableClose: true
    });
  }

  /** Filter rows matches. */
  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: IBannerElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id_banner + 1}`;
  }
}
