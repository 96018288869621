import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { RifaService } from 'src/app/shared/services/rifa.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { NavbarService } from 'src/app/shared/services/navbar.service';
import * as _ from 'lodash';
import { SubnavbarService } from '../../shared/services/subnavbar.service';

export interface Type {
  id: number;
  value: string;
}

@Component({
  selector: 'app-create-rifa',
  templateUrl: './create-rifa.component.html',
  styleUrls: ['./create-rifa.component.scss']
})
export class CreateRifaComponent implements OnInit {

  public loading: boolean;
  public status: boolean;
  public submitted: boolean;
  public ckeConfig: any;
  public reset: boolean;
  public idComponent: number;
  public nameComponent = 'Rifa';

  submenus: any;
  aux: any;
  public numeros_maximos: number;
  public costo: number;
  public cantidad: number;

  constructor(
    public dialogRef: MatDialogRef<CreateRifaComponent>,
    private notificationService: NotificationsService,
    public rifaService: RifaService,
    public subMenuService: SubnavbarService,
    private dialog: MatDialog,
  ) {
    this.status = false;
    this.submitted = false;
    this.loading = false;
    this.idComponent = this.rifaService.informationForm.get('id_rifa').value;
    this.numeros_maximos =  this.rifaService.informationForm.get('numeros_maximos').value;
      console.log(this.rifaService.informationForm.get('costo'));
  }

  ngOnInit() {
    this.reset = this.rifaService.informationForm.get('id_rifa').value;

    // Type
    //const type = this.types.find(c => c.id === this.rifaService.informationForm.get('type').value);
    //this.rifaService.informationForm.get('type').setValue(type);
    var id = this.rifaService.informationForm.get('id_rifa').value;
    this.rifaService.getRifa(id).subscribe((res : any) => {


    var costo = [];
    var cantidad= [];
    var i = 1;
    
    res.costos_rifa.forEach(item => {    
        console.log(item);
        costo.push(item.costo);
        (<HTMLInputElement>document.getElementById("costo"+i)).value = item.costo;

        cantidad.push(item.cantidad);
        i += 1;
      });

     (<HTMLInputElement>document.getElementById("costo")).value = costo.toString();
     (<HTMLInputElement>document.getElementById("cantidad")).value = cantidad.toString();


    },(error: any) =>{
      console.error(error);
    }, () => {
      console.log("RIFA OK");

    });


    // SubMenu
    this.subMenuService.getSubMenu().subscribe((res) => {
      this.aux = res;
      this.submenus = _.filter(this.aux, (o) => o.id_tipo_submenu > 39);
      this.aux = _.filter(this.aux, (o) => o.id_tipo_submenu < 45);

      const submenu = _.find(this.submenus, (o) => o.id === this.rifaService.informationForm.get('id_scsubmenu').value);
      
      this.rifaService.informationForm.get('id_scsubmenu').setValue(submenu);
    }, (error: any) => {
      console.error(error);
    }, () => {
      console.log('Observer got a complete notification');
    });

    //console.log(this.rifaService.informationForm.controls); 
  }

  get f() {  
    //console.log(this.rifaService.informationForm.controls);
    return this.rifaService.informationForm.controls; }

  add_costo(x){

    var tot = [];
    var tot2 = [];

    for (var i=1; i<=x; i++){
      var  cost: any = document.querySelector('#costo'+i);
      if(cost.value){
          tot.push(cost.value);
          tot2.push(i);
        }
      console.log(x);
    }

    const costo: any = document.querySelector('#costo');
    const cantidad: any = document.querySelector('#cantidad');
    costo.value =tot;
    cantidad.value =tot2;

  }


  onFileInput(fileInput: any, $event: any) {
    $event.preventDefault();
    fileInput.click();
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    this.rifaService.informationForm.controls.imagen.setValue(inputNode.files[0].name);

    this.rifaService.upload(inputNode.files[0], 'images').subscribe((data: any) => {
      console.log(data);
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  onFileSelected2() {
    const inputNode2: any = document.querySelector('#file2');
    this.rifaService.informationForm.controls.fondo.setValue(inputNode2.files[0].name);

    this.rifaService.upload(inputNode2.files[0], 'fondo').subscribe((data: any) => {
      console.log(data);
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  onFileSelected3() {
    const inputNode3: any = document.querySelector('#file3');
    this.rifaService.informationForm.controls.icono_animado.setValue(inputNode3.files[0].name);

    this.rifaService.upload(inputNode3.files[0], 'icono_animado').subscribe((data: any) => {
      console.log(data);
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }


  onFileSelected4() {
    const inputNode4: any = document.querySelector('#file4');
    this.rifaService.informationForm.controls.fondo_modal.setValue(inputNode4.files[0].name);

    this.rifaService.upload(inputNode4.files[0], 'fondo_modal').subscribe((data: any) => {
      console.log(data);
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }



  onSubmit() {
    if (this.rifaService.informationForm.valid) {
      if (!this.rifaService.informationForm.get('id_rifa').value) {
        this.rifaService.store(this.rifaService.informationForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.rifaService.update(this.rifaService.informationForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  onChange($event: any) {
    this.status = $event.checked;
  }

  onClose(confirmation: boolean) {
    this.rifaService.informationForm.reset();
    this.dialogRef.close(confirmation);
  }

}
