import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {CustomURLEncoder} from '../../urlencoder/component';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  registerForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      stock: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
      canjeados: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]+')])]
    });
  }

  getTickets() {
    return this.httpClient.get('/copecbff/admin/categoriaproducto/monitoreados');
  }


}
