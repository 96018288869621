import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from '../../shared/services/notifications.service';
import { ResourcesService } from '../../shared/services/resources.service';
import { SitiosService } from '../../shared/services/sitios.service';
import { RegionService } from '../../shared/services/region.service';
import { ISitiosElement } from '../../shared/interfaces/isitios-element';
import { IRegionElement } from '../../shared/interfaces/iregion-element';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';

@Component({
	selector: 'app-sitios',
	templateUrl: './sitios.component.html',
	styleUrls: ['./sitios.component.scss']
})
export class SitiosComponent implements OnInit {

	idComponent: number;
	nameComponent = 'Sitio';
	registerForm: FormGroup;
	loading: boolean;
	activo : boolean;
	submitted: boolean;
	regions: IRegionElement[];
	public ckeConfig: any;


	constructor(
		public dialogRef: MatDialogRef<SitiosComponent>,
		private notificationService: NotificationsService,
		public sitiosService: SitiosService,
		private resourcesService: ResourcesService,
		private regionService: RegionService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.activo = false;
		this.loading = false;
	}

	ngOnInit() {
		this.ckeConfig = {
			allowedContent: false,
			extraPlugins: 'divarea',
			forcePasteAsPlainText: true,
			toolbarGroups: [
			  { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
			  { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
			  { name: 'forms', groups: [ 'forms' ] },
			  { name: 'links', groups: [ 'links' ] },
			  { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
			  { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
			  { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
			  { name: 'insert', groups: [ 'insert' ] },
			  { name: 'colors', groups: [ 'colors' ] },
			  { name: 'tools', groups: [ 'tools' ] },
			  { name: 'others', groups: [ 'others' ] },
			  { name: 'about', groups: [ 'about' ] },
			  { name: 'styles', groups: [ 'styles' ] }
			],
			removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteText,PasteFromWord,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Underline,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Anchor,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,TextColor,Maximize,ShowBlocks,About',
			removeDialogTabs: 'link:advanced'
		  };


		if (this.data == null) {
			/* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando un sitio */
			this.registerForm = this.formBuilder.group({
				$key: null,
				nombre: '',
				direccion: null,
				telefono: null,
				web: null,
				especialidad: '',
				imagen: '',
				fechaInicio: null,
				fechaFin: null,
				orden: 1,
				activo: [false],
				descripcionPromocion:['', Validators.required],
				idRegion: null
			});
		} else {
			/* Si al abrir el modal (iniciar el componente) el parámetro no es nulo, se está editando una encuesta */
			/* Se setea el idComponent para que FormToolbarComponent sepa que se está editando */
			this.idComponent = this.data.id;
			/* Se corrige la ruta de la imagen de imagen para cargarla al formulario */
			let imagen = this.data.imagen.split('/');
			this.data.imagen = imagen[imagen.length - 1];
			/* Se llena la información del formulario */
			/* Se da formato a las fechas */
			let initDate = this.data.fechaInicio.split(' ');
			let endDate = this.data.fechaFin.split(' ');
			/* Se registra el formulario */
			this.registerForm = this.formBuilder.group({
				$key: this.data.id,
				nombre: this.data.nombre,
				direccion: this.data.direccion,
				telefono: this.data.telefono,
				web: this.data.web,
				especialidad: this.data.especialidad,
				imagen: this.data.imagen,
				fechaInicio: initDate[0] + 'T' + initDate[1],
				fechaFin: endDate[0] + 'T' + endDate[1],
				orden: this.data.orden,
				activo: this.data.activo,
				descripcionPromocion: this.data.descripcionPromocion,
				idRegion: this.data.idRegion
			});
		}
		// Regions
		this.regionService.getRegions().subscribe((res) => {
			this.regions = res;
		}, (error: any) => {
			console.error(error);
		}, () => {
			//const type = this.questions_types.find( o => o.id_tipo_pregunta_encuesta === this.subNavBarService.subMenuForm.get('id_submenu_type').value);
			//this.subNavBarService.subMenuForm.get('id_submenu_type').setValue(type);
		});
	}

	onSubmit() {
		if (this.registerForm.valid) {
			if (!this.registerForm.get('$key').value) {
				this.sitiosService.store(this.registerForm.value).subscribe((res) => {
					this.submitted = true;
					this.notificationService.message(':: Enviado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error: any) => {
					console.error(error);
				}, () => {
					console.log('Observer got a complete notification');
				});
			} else {
				this.sitiosService.update(this.registerForm.value).subscribe((res) => {
					this.submitted = true;
					this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error) => {
					console.error(error);
				}, () => {
					console.log('Observer got a complete notification');
				});
			}
		}
	}

	onFileInput(fileInput: any, $event: any) {
		$event.preventDefault();
		fileInput.click();
	}

	onFileSelected() {
		const inputNode: any = document.querySelector('#file') as HTMLInputElement;
		this.registerForm.controls.imagen.setValue(inputNode.files[0].name);
		this.resourcesService.upload(inputNode.files[0], 'images').subscribe((data: any) => {
			console.log(data);
		}, (error: any) => {
			console.error(error);
		}, () => {
			this.loading = false;
		});
	}

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}
}
