import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewsComponent } from './views/views.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { MenuComponent } from './menu/menu/menu.component';
import { BannerListComponent } from './banners/banner-list/banner-list.component';
import { PopupListComponent } from './popups/popup-list/popup-list.component';
import { BannercmsListComponent } from './cmsbanners/banner-list/banner-list.component';
import { SubmenuComponent } from './menu/submenu/submenu.component';
import { InformationListComponent } from './information/information-list/information-list.component';
import { RifaListComponent } from './rifas/rifa-list/rifa-list.component';
import { DetailFormatListComponent } from './detail-format/detail-format-list/detail-format-list.component';
import { ProductListComponent } from './products/product-list/product-list.component';
import { CompanyListComponent } from './companies/company-list/company-list.component';
import { LikeListComponent } from './like/like-list/like-list.component';
import { CategoryListComponent } from './categories/category-list/category-list.component';
import { PanelcategoryListComponent } from './panelcategories/panelcategory-list/panelcategory-list.component';
import { MarcaListComponent } from './marcas/marca-list/marca-list.component';
import { TagListComponent } from './tags/tag-list/tag-list.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { UserListComponent } from './users/user-list/user-list.component';
import { TicketListComponent } from './tickets/ticket-list/ticket-list.component';
import { AnalyticListComponent } from './analytics/analytic-list/analytic-list.component';
import { RrssComponent } from './analytics/rrss/rrss.component';
import { LayoutFrontComponent } from './layout-front/layout-front.component';
import { DashboardFrontComponent } from './dashboard-front/dashboard-front.component';
import { ItemListComponent } from './items/item-list/item-list.component';
import { ContenidosComponent } from './contenidos/contenidos/contenidos.component';
import { NoticiasComponent } from './noticias/noticias/noticias.component';
import { VideosComponent } from './videos/videos/videos.component';
import { SitiosListComponent } from './sitios/sitios-list/sitios-list.component';
import { VotacionesListComponent } from './votaciones/votaciones-list/votaciones-list.component';
import { EmailsListComponent } from './emails/emails-list/emails-list.component';
import { MailingEmailsListComponent } from './mailing-emails/mailing-emails-list/mailing-emails-list.component';
import { NoticiasResourcesListComponent } from './noticiasresources/noticiasresources-list/noticiasresources-list.component';
const routes: Routes = [{
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }, {
    path: 'views',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ViewsComponent }
    ]
  }, {
    path: 'navbars',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: MenuComponent }
    ]
  }, {
    path: 'emails',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: EmailsListComponent }
    ]
    },
    {
      path: 'mailingemails',
      component: DashboardComponent,
      canActivate: [AuthGuard],
      children: [
        { path: '', component: MailingEmailsListComponent }
      ]
      },
    {
      path: 'noticiasresources',
      component: DashboardComponent,
      canActivate: [AuthGuard],
      children: [
        { path: '', component: NoticiasResourcesListComponent }
      ]
      },
  {
    path: 'banners',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: BannerListComponent }
    ]
  },
   {
    path: 'contenidos',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ContenidosComponent }
    ]
  },
  {
   path: 'noticias',
   component: DashboardComponent,
   canActivate: [AuthGuard],
   children: [
     { path: '', component: NoticiasComponent }
   ]
 },
 {
  path: 'videos',
  component: DashboardComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: VideosComponent }
  ]
},
{
  path: 'sitios',
  component: DashboardComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: SitiosListComponent }
  ]
},
  {
    path: 'votaciones',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: VotacionesListComponent }
    ]
  },
   {
    path: 'popups',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: PopupListComponent }
    ]
  }, {
    path: 'submenu',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: SubmenuComponent }
    ]
  }, {
    path: 'informations',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: InformationListComponent }
    ]
  },{
    path: 'rifas',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: RifaListComponent }
    ]
  }, {
    path: 'detailsformat',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DetailFormatListComponent }
    ]
  }, {
    path: 'products',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ProductListComponent }
    ]
  },
  {
    path: 'companies',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: CompanyListComponent }
    ]
  }, {
    path: 'likes',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: LikeListComponent }
    ]
  }, {
    path: 'categories',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: CategoryListComponent }
    ]
  }, {
    path: 'panelcategories',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: PanelcategoryListComponent }
    ]
  },{
    path: 'marcas',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: MarcaListComponent }
    ]
  }, {
    path: 'tags',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: TagListComponent }
    ]
  }, {
    path: 'users',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: UserListComponent }
    ]
  }, {
    path: 'tickets',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: TicketListComponent }
    ]
  },
  {
    path: 'analytics',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: AnalyticListComponent }
    ]
  },
  {
    path: 'rrss',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: RrssComponent }
    ]
  }, {
    path: 'cmsbanners',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: BannercmsListComponent }
    ]
  },{
    path: 'items',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ItemListComponent }
    ]
  }, {
    path: 'front',
    component: DashboardFrontComponent
  }];

  // }, {
  //   path: '**',
  //   redirectTo: '/login', // NotFoundComponent
  //   pathMatch: 'full'
  // }, {

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
