import { Component, OnInit } from '@angular/core';
import { TicketsService } from 'src/app/shared/services/tickets.service';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { ProfileService } from '../../shared/services/profile.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss']
})
export class CreateTicketComponent implements OnInit {

  loading: boolean;
  estado: boolean;
  submitted: boolean;
  profiles: any;
  public reset: boolean;
  public idComponent: number;
  public nameComponent = 'Tickets';

  constructor(
    public ticketService: TicketsService,
    public profileService: ProfileService,
    public dialogRef: MatDialogRef<CreateTicketComponent>,
    private notificationService: NotificationsService
  ) {
    this.estado = false;
    this.idComponent = this.ticketService.registerForm.get('id_usuario_cms').value;
   }

  ngOnInit() {
    this.reset = this.ticketService.registerForm.get('id_usuario_cms').value;
    // PROFILES
    this.profileService.getProfiles().subscribe((res) => {
      this.profiles = res;
    }, (error: any) => {
      console.error(error);
    }, () => {
      const profile = _.find(this.profiles, (o) => o.id_perfil === this.ticketService.registerForm.get('perfil').value);
      this.ticketService.registerForm.get('perfil').setValue(profile);
    });
  }

 

  get f() { return this.ticketService.registerForm.controls; }

  onChange($event: any) {
    this.estado = $event.checked;
  }

  onClose(confirmation: boolean) {
    this.ticketService.registerForm.reset();
    this.dialogRef.close(confirmation);
  }
}
