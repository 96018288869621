import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { MarcaService } from '../../shared/services/marca.service';
import { IMarcaElement } from 'src/app/shared/interfaces/imarca-element';
import { CreateMarcaComponent } from '../create-marca/create-marca.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-marca-list',
  templateUrl: './marca-list.component.html',
  styleUrls: ['./marca-list.component.scss']
})
export class MarcaListComponent implements OnInit {

  loading: boolean;
  dataSource = new MatTableDataSource<IMarcaElement>();
  selection = new SelectionModel<IMarcaElement>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public displayedColumns: string[] = [
    'id',
    'nombre_marca',
    'fecha_creacion',
    'actions'
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    public marcaService: MarcaService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) { this.loading = true; }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getCategories();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getCategories() {
    this.activeRouter.queryParams.subscribe(params => {
      this.marcaService.getCategories(params.id).subscribe((data: any) => {
        this.dataSource.data = data as IMarcaElement[];
      }, (error: any) => {
        console.error(error);
      }, () => { this.loading = false; });
    });
  }

  create() {
    this.dialog.open(CreateMarcaComponent, {
      width: '60%',
      height: '85%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.getCategories();
      }
    });
  }

  edit(row: any) {


    const data = {
      id: row.id,
      nombre_marca: row.nombre_marca,
      fecha_creacion : row.fecha_creacion
      
    };

    this.marcaService.edit(data);
    this.create();
  }

  delete(id: any) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
      if (res) {
        this.loading = true;
        this.marcaService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => { this.getCategories(); });
      }
    });
  }

  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: IMarcaElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

}
