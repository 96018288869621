import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { InformationService } from '../../shared/services/information.service';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CreateInformationComponent } from '../create-information/create-information.component';
import { JwtService } from 'src/app/shared/services/jwt.service';

export interface InformationElement {
  id: number;
  nombre: string;
  tipo: number;
  html: string;
  estado: number;
  id_submenu: number;
}

@Component({
  selector: 'app-information-list',
  templateUrl: './information-list.component.html',
  styleUrls: ['./information-list.component.scss']
})
export class InformationListComponent implements OnInit {

  loading: boolean;
  dataSource = new MatTableDataSource<InformationElement>();
  selection = new SelectionModel<InformationElement>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public displayedColumns: string[] = [
    // 'select',
    'nombre',
    'tipo',
    'estado',
    'actions'
  ];

  constructor(
    private informationService: InformationService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) { this.loading = true; }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getInformations();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getInformations() {
    this.informationService.getInformations().subscribe((data: any) => {
      this.dataSource.data = data as InformationElement[];
    }, (error) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  create() {
    this.dialog.open(CreateInformationComponent, {
      width: '60%',
      // height: '85%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.getInformations();
      }
    });
  }

  edit(row: any) {
    const data = {
      id: row.id,
      id_submenu: row.id_submenu,
      name: row.nombre,
      type: row.tipo,
      status: row.estado,
      content: row.html
    };
    this.informationService.edit(data);
    this.create();
  }

  delete(id: number) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
      if (res) {
        this.loading = true;
        this.informationService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
          console.log(data);
          }, (error: any) => {
            console.error(error);
          }, () => { this.getInformations(); }
        );
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: InformationElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

}
