import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.scss']
})
export class CloseComponent implements OnInit {

  @Output() closed = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  closeWindow() {
    this.closed.emit(true);
  }

}
