import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { SubnavbarService } from '../../shared/services/subnavbar.service';
import { Observable, Subject, of, concat, Subscription } from 'rxjs';
import { ProductService } from 'src/app/shared/services/product.service';
import { ColorService } from 'src/app/shared/services/color.service';
import { IProductElement } from 'src/app/shared/interfaces/iproduct-element';
import { ITypeElement } from '../../shared/interfaces/itype-element';
import { SubmenusTypeService } from 'src/app/shared/services/submenus-type.service';
import * as _ from 'lodash';
import { IContenidosElement } from 'src/app/shared/interfaces/icontenidos-element';
import { ContenidosService } from 'src/app/shared/services/contenidos.service';
import { ISitiosElement } from 'src/app/shared/interfaces/isitios-element';
import { SitiosService } from 'src/app/shared/services/sitios.service';
export interface Color {
	id: number;
	value: string;
}

@Component({
	selector: 'app-create-submenu',
	templateUrl: './create-submenu.component.html',
	styleUrls: ['./create-submenu.component.scss']
})
export class CreateSubmenuComponent implements OnInit, OnDestroy {

	public loading: boolean;
	public actived: boolean;
	public submitted: boolean;
	public folder:string;
	public value:string[];
	public submenus:any;
	product:IProductElement;
	product$:Observable<any>;
	contenido:IContenidosElement;
	contenido$:Observable<any>;
	sitio:ISitiosElement;
	sitio$:Observable<any>;
	selectedContenido:any;
	selectedProduct:any;
	selectedSitio:any;
	productLoading=false;
	contenidoLoading=false;
	sitioLoading=false;
	productInput$ = new Subject<string>();
	contenidoInput$=new Subject<string>();
	sitioInput$=new Subject<String>();
	idComponent: number;
	nameComponent = 'Submenú';
	public subscription: Subscription;

	conditions = [{
		value: true,
		text: 'Activo'
	}, {
		value: false,
		text: 'Inactivo'
	}];

	constructor(
		public dialogRef: MatDialogRef<CreateSubmenuComponent>,
		private notificationService: NotificationsService,
		public subNavBarService: SubnavbarService,
		private resourcesService: ResourcesService,
		private productService: ProductService,
		private sitiosService:SitiosService,
		private contenidosService : ContenidosService,
		private submenusTypeService: SubmenusTypeService,
		private colorService: ColorService
	) {
		this.actived = true;
		this.idComponent = this.subNavBarService.subMenuForm.get('id').value;
	}

	ngOnInit() {
		// Menu Type
		this.submenusTypeService.getSubmenusType().subscribe((res) => {
			this.submenus = res;
		}, (error: any) => {
			console.error(error);
		}, () => {
			const type = this.submenus.find( o => o.id_tipo_submenu === this.subNavBarService.subMenuForm.get('id_submenu_type').value);
			this.subNavBarService.subMenuForm.get('id_submenu_type').setValue(type);
			this.desplegarEntrada(type);
		});

		// visibleTop
		const visibleTop = this.conditions.find(c => c.value === this.subNavBarService.subMenuForm.get('visible_top').value);
		this.subNavBarService.subMenuForm.get('visible_top').setValue(visibleTop);

		// visibleOnlyLogin
		const visibleOnlyLogin = this.conditions.find(c => c.value === this.subNavBarService.subMenuForm.get('visible_login').value);
		this.subNavBarService.subMenuForm.get('visible_login').setValue(visibleOnlyLogin);

		const idSubmenu  = this.subNavBarService.subMenuForm.get('id').value;
		const buscarConSubmenu = true;
		const noContenidos = (this.subNavBarService.subMenuForm.get('idListContenidos').value == null ? true : false);

		/* Obtiene los contenidos de un submenú */
		if(idSubmenu!=null && !noContenidos){
			this.contenidosService.getContenidos(idSubmenu, buscarConSubmenu).subscribe((res: any) => {
				/*acá problemas */
				if(res!=null){
					this.selectedContenido = res;
				}
			}, (error: any) => {
				console.error(error);
			}, () => {
				console.log('Observer got a complete notification');
			});
		}
		/* Obtiene los productos de un submenú */
		if(idSubmenu!=null) {
			this.productService.getProducts(idSubmenu, buscarConSubmenu).subscribe((res: any) => {
				this.selectedProduct = res;
			}, (error: any) => {
				console.error(error);
			}, () => {
				console.log('Observer got a complete notification');
			});
			}

			/* Obtiene los sitios de un submenú */
			if(idSubmenu!=null) {
				this.subNavBarService.getSitios(idSubmenu).subscribe((res: any) => {
					this.selectedSitio = res;
				}, (error: any) => {
					console.error(error);
				}, () => {
					console.log('Observer got a complete notification');
				});
				}


			this.contenido$ = this.contenidosService.getContenidos();
			this.product$ = this.productService.getProducts();
			this.sitio$=this.sitiosService.getSites();
	}

	onSubmit() {
		if (this.subNavBarService.subMenuForm.valid) {
			if (!this.subNavBarService.subMenuForm.get('id').value) {
				this.subscription = this.subNavBarService.store(this.subNavBarService.subMenuForm.value).subscribe((res: any) => {
					this.submitted = true;
					this.notificationService.message(':: Enviado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error: any) => {
					console.error(error);
				}, () => {
					console.log('Observer got a complete notification');
				});
			} else {
				this.subscription = this.subNavBarService.update(this.subNavBarService.subMenuForm.value).subscribe((res: any) => {
					this.submitted = true;
					this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error) => {
					console.error(error);
				}, () => {
					console.log('Observer got a complete notification');
				});
			}
		}
	}

	get f() { return this.subNavBarService.subMenuForm.controls; }

	onFileInput(fileInput: any, $event: any, folder: string) {
		this.folder = folder;
		$event.preventDefault();
		fileInput.click();
	}

	onFileSelected() {
		const inputNode: any = document.querySelector('#file');
		if (this.folder == 'images') {
			this.subNavBarService.subMenuForm.controls.icon.setValue(inputNode.files[0].name);
		} else {
			this.subNavBarService.subMenuForm.controls.image.setValue(inputNode.files[0].name);
		}

		this.resourcesService.upload(inputNode.files[0], this.folder).subscribe((data: any) => {
			console.log(data);
		}, (error: any) => {
			console.error(error);
		}, () => {
			this.loading = false;
		});
	}

	public desplegarEntrada(parametro) {
		switch(parametro.nombre) {
   		case 'CATEGORIA-CONTENIDO-NOTICIAS-1':
			case 'CATEGORIA-CONTENIDO-NOTICIAS-2':
			case 'CATEGORIA-CONTENIDO-NOTICIAS-3':
			case 'CATEGORIA-CONTENIDO-NOTICIAS-4':
			case 'CATEGORIA-CONTENIDO-NOTICIAS-5': {
				(document.querySelector('#productList') as  HTMLInputElement).style.display = 'none';
				(document.querySelector('#contenidosList') as  HTMLInputElement).style.display = 'block';
				(document.querySelector('#sitiosList') as  HTMLInputElement).style.display = 'none';
      	break;
   		}
   		case 'CATEGORIA-CONTENIDO-VIDEOS-1':
			case 'CATEGORIA-CONTENIDO-VIDEOS-2':
			case 'CATEGORIA-CONTENIDO-VIDEOS-3':
			case 'CATEGORIA-CONTENIDO-VIDEOS-4':
			case 'CATEGORIA-CONTENIDO-VIDEOS-5': {
				(document.querySelector('#productList') as  HTMLInputElement).style.display = 'none';
				(document.querySelector('#contenidosList') as  HTMLInputElement).style.display = 'block';
				(document.querySelector('#sitiosList') as  HTMLInputElement).style.display = 'none';
      	break;
   		}

			case 'TIPO PRODUCTO E-COMERCE':
			case 'TIPO CANJE ECOMMERCE2':
			case 'TIPO CANJE ECOMMERCE3':
			case 'TIPO CANJE ECOMMERCE4':
			case 'TIPO CANJE ECOMMERCE5':
			case 'TIPO CANJE ECOMMERCE6': {
				(document.querySelector('#productList') as  HTMLInputElement).style.display = 'block';
				(document.querySelector('#contenidosList') as  HTMLInputElement).style.display = 'none';
				(document.querySelector('#sitiosList') as  HTMLInputElement).style.display = 'none';
				break;
			}
			case 'CATEGORIA-SITIOS-1':
			case 'CATEGORIA-SITIOS-2':
			case 'CATEGORIA-SITIOS-3':
			case 'CATEGORIA-SITIOS-4':
			case 'CATEGORIA-SITIOS-5': {
				(document.querySelector('#productList') as  HTMLInputElement).style.display = 'none';
				(document.querySelector('#contenidosList') as  HTMLInputElement).style.display = 'none';
				(document.querySelector('#sitiosList') as  HTMLInputElement).style.display = 'block';
				break;
			}
   		default: {
				(document.querySelector('#productList') as  HTMLInputElement).style.display = 'none';
				(document.querySelector('#contenidosList') as  HTMLInputElement).style.display = 'none';
				(document.querySelector('#sitiosList') as  HTMLInputElement).style.display = 'none';
      break;
   		}
		}
	}

	onChange($event: any) {
		this.actived = $event.checked;
		console.log(this.actived);
	}

	onClose(confirmation: boolean) {
		this.subNavBarService.subMenuForm.reset(this.subNavBarService.initialValues);
		this.dialogRef.close(confirmation);
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
