import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { INoticiaResourceElement } from '../interfaces/inoticiaresource-element';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ResourcesService {
  // @ViewChild('selectedFile') selectedFileEl;
  constructor(
    protected httpClient: HttpClient
  ) { }

  getResources() {
    return this.httpClient.get('/copecbff/admin/resource/all');
  }

  getImageByRoute(route: string) {
    const folder = 'images';
    const name = 'temario-scrum-diacosasesores.png';
    return this.httpClient.get('/copecbff/admin/resource/' + folder + '?name=' + name);
  }


  upload(file: File, folder: string) {
		const formData: FormData = new FormData();
		formData.append('file', file);
		formData.append('folder', folder);
		return this.httpClient.post<any>(
			'/copecbff/admin/resources',
			formData);
	}

setResourceNotcia(data: any) {
  const formData: FormData = new FormData();
  formData.append('link', data.link);
  formData.append('categoria', data.categoria);
  formData.append('orden', data.orden);
  formData.append('nombre', data.nombreResource);
  return this.httpClient.post<any>(
    '/copecbff/admin/resources/comunicados/set',
    formData);
}


updateResourceNotcia(data: any) {
  const formData: FormData = new FormData();
  formData.append('nombre', data.nombreResource);
  formData.append('link', data.link);
  formData.append('categoria', data.categoria);
  return this.httpClient.post<any>(
    '/copecbff/admin/resources/comunicados/set',
    formData);
}

  getResourcesNoticias(): Observable<INoticiaResourceElement[]> {
		return this.httpClient.get<INoticiaResourceElement[]>('/copecbff/admin/resources/comunicados');
	}

	uploadResourceNoticia(file: File, folder: string, link: string, orden: number, categoria:number,rename: boolean) {
		const formData: FormData = new FormData();
		const renameString = rename? "1":"0";
		formData.append('file', file);
		formData.append('folder', folder);
    formData.append('link', link);
    formData.append('categoria', categoria.toString());
		formData.append('rename', renameString);
		return this.httpClient.post<any>(
			'/copecbff/admin/resources/comunicados',
			formData);
	}

  delete(id: number) {
    return this.httpClient.delete('/copecbff/admin/resources/' + id);
  }
}
