import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sumarray',
})
export class SumarrayPipe implements PipeTransform {

  // El parametro object representa, los valores de las propiedades o indice
  transform(objects): number {
	var sum = 0;
  var aux = Object.values(objects['value']);
  aux.shift();
  
  aux.forEach( item => {
    //k =  parseInt(k);
    if ( isNaN( Number(item) ) == false ){
     sum += parseInt( (String) (item) );
    }
  });
    return sum;
  }

}