import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { JwtService } from 'src/app/shared/services/jwt.service';

declare var gapi : any;

@Component({
  selector: 'app-analytic-list',
  templateUrl: './analytic-list.component.html',
  styleUrls: ['./analytic-list.component.scss']
})
export class AnalyticListComponent implements OnInit {

  loading: boolean;
  dataSource = new Array();
  selection = new SelectionModel<any>(true, []);


  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;



  constructor(
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) { }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      //this.loadjs();
      this.getApiGoogle();
      //this.getToken();

    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }


  getApiGoogle(){
    $.ajax({
        type: "GET",
        url: "ga-oauth",
        success: function(data){
          console.log(data);
          gapi.analytics.ready(function() {
            gapi.analytics.auth.authorize({
              'serverAuth': {
              'access_token': data
              }
            });

            var dataChart = new gapi.analytics.googleCharts.DataChart({
              query: {
                'ids': 'ga:93979899',
                'metrics': 'ga:sessions,ga:users',
                'dimensions': 'ga:date',
                'start-date': '2020-01-01',
                'end-date': 'today'
              },
              chart: {
                container: 'view-selector-container',
                type: 'LINE',
                options: {
                  width: '100%'
                }
              }
            });
            dataChart.execute();
            
            var dataChart2 = new gapi.analytics.googleCharts.DataChart({
              query: {
                'ids': 'ga:93979899',
                'metrics': 'ga:sessions',
                'dimensions': 'ga:browser',
                'start-date': '2020-01-01',
                'end-date': 'today',
                'max-results': 6,
                'sort': '-ga:sessions'
              },
              chart: {
                container: 'chart-session-browser',
                type: 'PIE',
                options: {
                  width: '50%',
                  pieHole: 4/9
                }
              }
            });
            dataChart2.execute();
            
            var dataChart3 = new gapi.analytics.googleCharts.DataChart({
              query: {
                'ids': 'ga:93979899',
                'metrics': 'ga:sessions',
                'dimensions': 'ga:deviceCategory',
                'start-date': '2020-01-01',
                'end-date': 'today',
                'max-results': 6,
                'sort': '-ga:sessions'
              },
              chart: {
                container: 'chart-session-device',
                type: 'PIE',
                options: {
                  width: '50%',
                  pieHole: 4/9
                }
              }
            });
            dataChart3.execute();
        
            var dataChart4 = new gapi.analytics.googleCharts.DataChart({
              query: {
                'ids': 'ga:93979899',
                'metrics': 'ga:sessions',
                'dimensions': 'ga:mobileDeviceModel',
                'start-date': '2020-01-01',
                'end-date': 'today',
                'max-results': 6,
                'sort': '-ga:sessions'
              },
              chart: {
                container: 'chart-session-deviceinfo',
                type: 'PIE',
                options: {
                  width: '50%',
                  pieHole: 4/9
                }
              }
            });
            dataChart4.execute();
            
            var dataChart5 = new gapi.analytics.report.Data({
              query: {
                'ids': 'ga:93979899',
                'metrics': 'ga:avgSessionDuration,ga:newUsers,ga:sessions,ga:visits,ga:pageviewsPerSession,ga:users',
                'start-date': '2020-01-01',
                'end-date': 'today'
              }
            });
            dataChart5.on('success', function(response) {
              var dataSource =  response.rows[0];
              

              dataSource[0] = parseInt(dataSource[0]);

              dataSource[4] = parseFloat(dataSource[4]).toFixed(2);
              dataSource[5] = parseFloat(dataSource[5]).toFixed(2);

              var str = '';
              str+= '<div class="mat-card" style="width:30%; display: inline-grid; background-color: #007bff !important;color:white"><b>'+parseInt(dataSource[0])+' segs.</b><hr>Duración Sesión</div>&nbsp;';
              str+= '<div class="mat-card" style="width:30%; display: inline-grid; background-color: #007bff !important;color:white"><b>'+dataSource[1]+'</b><hr>Usuarios únicos</div>&nbsp;';
              str+= '<div class="mat-card" style="width:30%; display: inline-grid; background-color: #007bff !important;color:white"><b>'+dataSource[2]+'</b><hr>Sesiones</div><br><br>';
              str+= '<div class="mat-card" style="width:30%; display: inline-grid; background-color: #007bff !important;color:white"><b>'+dataSource[3]+'</b><hr>N° de páginas Visitadas</div>&nbsp;';
              str+= '<div class="mat-card" style="width:30%; display: inline-grid; background-color: #007bff !important;color:white"><b>'+dataSource[4]+'</b><hr>N° pág promedio por sesión</div>&nbsp;';
              str+= '<div class="mat-card" style="width:30%; display: inline-grid; background-color: #007bff !important;color:white"><b>'+dataSource[5]+'</b><hr>Usuarios activos promedio por días</div><br/>';

              $("#indicators").html(str);
              
            });
            dataChart5.execute();
          });
        }
    });
  }

}
