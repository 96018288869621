import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { BannercmsService } from 'src/app/shared/services/bannercms.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.scss']
})
export class BannercmsListComponent implements OnInit {

  loading: boolean;
  meses = ["Enero","Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  selection = new SelectionModel<any>(true, []);


  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;



  constructor(
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    private bannercmsService: BannercmsService,
    protected jwtService: JwtService
  ) { }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {


    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  buscar(anio, mes) {
    var mes = mes.value +1;
    if (mes < 10)
      mes = "0" + mes.toString();

    console.log(anio.value+"_"+mes);
    var result = '';
    this.bannercmsService.getImages(anio.value+"_"+mes).subscribe((res: any) => {
      
      console.log(res);
      
      res.forEach(item => {

        item = item.replace("/var/www/html/", "http://68.183.111.73/");
        result += '<img src="'+item+'" style="width: 30%; padding-right: 5px"/>';
        });
    }, (error: any) => {
      console.error(error);
    }, () => {
      document.querySelector("#imagenes").innerHTML= result;
      (document.querySelector("#imagenes") as HTMLInputElement).style.display = "block";
    });
  }

  importar(anio, mes) {
    var mes = mes.value +1;
    if (mes < 10)
      mes = "0" + mes.toString();

    console.log(anio.value+"_"+mes);
    //var banner =  document.querySelector("#banner") as HTMLInputElement;
    //console.log(banner.files[0]);

    const inputNode: any = document.querySelector('#banner');
    var formData = new FormData(); 
    formData.append("file", inputNode.files[0], inputNode.files[0].name); 

    this.bannercmsService.setimage(anio.value+"_"+mes, formData).subscribe((res) => {
      
      console.log(res);

    }, (error: any) => {
      console.error(error);
    }, () => {
    });
  }



}
