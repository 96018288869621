import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../../shared/services/category.service';
import { ICategoryElement } from 'src/app/shared/interfaces/icategory-element';
import { CreateCategoryComponent } from '../create-category/create-category.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

  loading: boolean;
  dataSource = new MatTableDataSource<ICategoryElement>();
  selection = new SelectionModel<ICategoryElement>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public displayedColumns: string[] = [
    // 'select',
    'nombre',
    // 'descripcion',
    'tipo',
    'actions'
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    public categoryService: CategoryService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) { this.loading = true; }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getCategories();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getCategories() {
    this.activeRouter.queryParams.subscribe(params => {
      this.categoryService.getCategories(params.id).subscribe((data: any) => {
        this.dataSource.data = data as ICategoryElement[];
      }, (error: any) => {
        console.error(error);
      }, () => { this.loading = false; });
    });
  }

  create() {
    this.dialog.open(CreateCategoryComponent, {
      width: '60%',
      height: '85%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.getCategories();
      }
    });
  }

  edit(row: any) {

    const image = row.imagen.split('/');

    const data = {
      id: row.id_categoria_producto,
      name: row.nombre,
      terms: row.condiciones_legales,
      image: image[3],
      str_index: row.strIndex,
      color_hover: row.color_hover,
      type: row.tipo,
      id_item: row.rubro.id_rubro,
      nominative: row.nominativa
    };

    this.categoryService.edit(data);
    this.create();
  }

  delete(id: any) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
      if (res) {
        this.loading = true;
        this.categoryService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => { this.getCategories(); });
      }
    });
  }

  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ICategoryElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id_categoria_producto + 1}`;
  }

}
