import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { MarcaService } from 'src/app/shared/services/marca.service';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ItemService } from '../../shared/services/item.service';
import * as _ from 'lodash';

export interface Type {
  id: number;
  value: string;
}

@Component({
  selector: 'app-create-marca',
  templateUrl: './create-marca.component.html',
  styleUrls: ['./create-marca.component.scss']
})
export class CreateMarcaComponent implements OnInit {

  public loading: boolean;
  public subscription: Subscription;
  public submitted: boolean;
  public ckeConfig: any;
  public reset: boolean;
  public idComponent: number;
  public nameComponent = 'Marca';
  public items: any;


  constructor(
    public dialogRef: MatDialogRef<CreateMarcaComponent>,
    private notificationService: NotificationsService,
    public marcaService: MarcaService,
    public itemService: ItemService,
    private resourcesService: ResourcesService,
  ) {
    this.idComponent = this.marcaService.registerForm.get('id').value;
  }

  ngOnInit() {
    this.reset = this.marcaService.registerForm.get('id').value;
   


  }

  onSubmit() {
    if (this.marcaService.registerForm.valid) {
      if (!this.marcaService.registerForm.get('id').value) {
        this.subscription = this.marcaService.store(this.marcaService.registerForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Guardado satisfactoriamente', 'success');
          this.onClose(true);
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('Client-side error');
          } else {
            console.log('Server-side error');
          }
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.subscription = this.marcaService.update(this.marcaService.registerForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  get f() { return this.marcaService.registerForm.controls; }

  onFileInput(fileInput: any, $event: any) {
    $event.preventDefault();
    fileInput.click();
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    this.marcaService.registerForm.controls.image.setValue(inputNode.files[0].name);

    this.resourcesService.upload(inputNode.files[0], 'images').subscribe((data: any) => {
      console.log(data);
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  onClose(confirmation: boolean) {
    this.marcaService.registerForm.reset();
    this.dialogRef.close(confirmation);
  }
}
