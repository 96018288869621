import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from '../../shared/services/notifications.service';
import { IContenidosElement } from 'src/app/shared/interfaces/icontenidos-element';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { ContenidosService } from 'src/app/shared/services/contenidos.service';
import { CreateContenidosComponent } from '../create-contenidos/create-contenidos.component';
import { Location } from '@angular/common';


import * as _moment from 'moment';
import 'moment/locale/es';

const moment = _moment;
moment.locale('es');
@Component({
  selector: 'app-contenidos',
  templateUrl: './contenidos.component.html',
  styleUrls: ['./contenidos.component.scss']
})

export class ContenidosComponent implements OnInit {
  loading: boolean;
  dataSource = new MatTableDataSource<IContenidosElement>();
  selection = new SelectionModel<IContenidosElement>(true, []);
  hidden: boolean;



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(
    private location: Location,
    private navigator: Router,
    private activeRouter: ActivatedRoute,
    protected contenidosService: ContenidosService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) {
      this.loading = true;
  }

  public displayedColumns: string[] = [
    // 'select',
    'nombre',
    'orden',
    'estado',
    'actions'
  ];

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getContenidos();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }


  getContenidos() {
    this.activeRouter.queryParams.subscribe(params => {
    this.hidden = (params.id) ? true : false;
    this.contenidosService.getContenidos(params.id, params.submenu).subscribe((data: any) => {
    this.dataSource.data = data as IContenidosElement[];
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
      });
  }

openModal(data ? : any) : void {

    this.dialog.open(CreateContenidosComponent, {
      width: '60%',
      height: '85%',
      autoFocus: true,
      disableClose: true,
      data : data
    }).afterClosed().subscribe(() => {
        this.loading = true; // posible problema
        this.getContenidos();
    });
  }


/* Actualizar estos metodos */
  getNoticias(id: number) {
    const contenido = true;
    this.navigator.navigate(['noticias'], { queryParams: { id, contenido }});
  }

  getVideos(id: number) {
    const contenido = true;
    this.navigator.navigate(['videos'], { queryParams: { id, contenido }});
  }

  delete(id: number) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?')
    .afterClosed().subscribe(res => {
      if (res) {
        this.contenidosService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => { this.ngOnInit(); });
      }
    });
  }


  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: IContenidosElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  backTo() {
    localStorage.removeItem('id_scsubmenu');
    this.location.back();
  }

}
