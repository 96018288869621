import { Component, OnInit, ViewChild } from '@angular/core';
import { VotacionesService } from '../../shared/services/votaciones.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { VotacionesComponent } from '../votaciones/votaciones.component';
import { NotificationsService } from '../../shared/services/notifications.service';
import { IVotacionesElement } from 'src/app/shared/interfaces/ivotaciones-element';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-votaciones-list',
  templateUrl: './votaciones-list.component.html',
  styleUrls: ['./votaciones-list.component.scss']
})
export class VotacionesListComponent implements OnInit {


  public displayedColumns: string[] = [
    'id',
    'fechaCreacion',
    'nombre',
    'votosPorCustomer',
    'estado',
    'actions'
  ];

  loading: boolean;
  dataSource = new MatTableDataSource<IVotacionesElement>();
  selection = new SelectionModel<IVotacionesElement>(true, []);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private location: Location,
    private navigator: Router,
    private activeRouter: ActivatedRoute,
    private votacionesService: VotacionesService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getVotaciones();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getVotaciones(): void {
    this.votacionesService.getVotaciones().subscribe(
      (data: any) => {
        this.dataSource.data = data as IVotacionesElement[];
      },(error) => {
        console.error(error);
      }, () => {
        this.loading = false;
      }
    );
  }

  openModal(data?: any): void {
    this.dialog.open(VotacionesComponent, {
      width: '60%',
      height: '85%',
      autoFocus: true,
      disableClose: true,
      data: data
    }).afterClosed().subscribe(() => {
      this.getVotaciones();
    });
  }

  getVideosVotaciones(id: number) {
    const contenido = true;
    this.navigator.navigate(['videos'], { queryParams: { id, contenido }});
  }

  delete(id:number): void {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
      if (res) {
        this.loading = true;
        this.votacionesService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => {
          this.getVotaciones();
        });
      }
    });
  }

  /** Filter rows matches. */
  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
