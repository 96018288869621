import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from '../../shared/services/jwt.service';
import {NgForm} from '@angular/forms';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import * as jquery from 'jquery';
import * as AdminLte from 'admin-lte';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading: boolean;
  color = 'warn';
  mode = 'indeterminate';
  value = 50;

  constructor(
    private router: Router,
    protected jwtService: JwtService,
    private notificationService: NotificationsService
  ) { this.loading = false; }

  ngOnInit() {
    /* Esto es para evitar errores de posicionamiento del login container */
    AdminLte.Layout._jQueryInterface.call($('body'));
    document.body.className = 'hold-transition login-page login-copec';


  }

  onSubmit(form: { value: { username: string; passwd: string; }; }) {
    this.loading = true;
    // if (form.value.username === 'spring') {
    //   this.notificationService.message(':: ScotiaID o Password no son correctos', 'warn');
    //   this.loading = false;
    // } else {
    this.jwtService.login(form.value.username, form.value.passwd).subscribe((res) => {
      const accessToken = res.headers.get('Authorization').split(' ');
      localStorage.setItem('access_token', accessToken[1]);
    }, (error) => {
      this.notificationService.message(':: Copec ID o Password no son correctos', 'warn');
      this.loading = false;
    }, () => {
      this.router.navigate(['dashboard']);
    });
    // }
  }

  ngOnDestroy(): void {
    document.body.className = '';
  }



}
