import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CustomURLEncoder} from '../../urlencoder/component';

import * as _moment from 'moment';
import 'moment/locale/es';

const moment = _moment;
moment.locale('es');

//const url = 'api_panel';
const url = 'http://68.183.111.73/api';


@Injectable({
  providedIn: 'root'
})
export class PanelcategoryService {
  
  registerForm: FormGroup;
  tags: Array<number>;
  initialValues: any;

  constructor(
    protected httpClient: HttpClient,
    public formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      id: null,
      nombre_categoria: ['', Validators.required],
      dateCreate_at: [moment(), Validators.required],
      dateUpdate_at: [moment(), Validators.required]
    });
    this.initialValues = this.registerForm.value;
  }

  getCategories(id?: number, submenu: boolean = false) {
      if (!id) {
        return this.httpClient.get(url+'/categoria');
      } else {
        return this.httpClient.get(url+'/categoria/' + id);
      }
  }


  listCategories() {
        return this.httpClient.get(url+'/api/categoria');
  }

  edit(data: any): void {
    this.registerForm.setValue(data);
  }

  store(data: any) {

    this.tags =  [];
    const date_upd = moment(data.dateUpdate_at);
    const date_cre = moment();

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre_categoria', data.nombre_categoria)
      .set('dateCreate_at', date_cre.format('YYYY-MM-DD HH:mm:ss'))
      .set('dateUpdate_at', date_upd.format('YYYY-MM-DD HH:mm:ss'))

    return this.httpClient.post<any>(url+'/categoria', body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any) {

    this.tags = [];
    const date_upd = moment(data.dateUpdate_at);

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('id', data.id)
      .set('nombre_categoria', data.nombre_categoria)
      .set('dateUpdate_at', date_upd.format('YYYY-MM-DD HH:mm:ss'))
    return this.httpClient.put<any>(
      url+'/categoria/'+data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
  }

  delete(id: string) {
        return this.httpClient.delete(url+'/categoria/'+id);
  }
}
