import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { JwtService } from '../services/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: JwtService, private router: Router) { }

  canActivate() {
    if (localStorage.getItem('access_token') !==  null) {
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }
}
