import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailService } from '../../shared/services/email.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from '../../shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MailingEmailsComponent } from '../mailing-emails/mailing-emails.component';
import { NotificationsService } from '../../shared/services/notifications.service';
import { MailingEmailService } from '../../shared/services/mailing-emails.service';
import { JwtService } from '../../shared/services/jwt.service';
import { IMailingEmailElement } from 'src/app/shared/interfaces/imailingemail-element';
@Component({
  selector: 'app-mailing-emails-list',
  templateUrl: './mailing-emails-list.component.html',
  styleUrls: ['./mailing-emails-list.component.scss']
})
export class MailingEmailsListComponent implements OnInit {

	public displayedColumns: string[] = [
		'idCampana',
		'fechaCreacion',
		'fechaEnvio',
		'asunto',
		'tipo',
		'estado',
		'activo',
		'actions'
	];

	loading: boolean;
	dataSource = new MatTableDataSource<IMailingEmailElement>();
	selection = new SelectionModel<IMailingEmailElement>(true, []);

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

	constructor(
		private mailingEmailService: MailingEmailService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService
	) {
		this.loading = true;
	}

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getMailingEmails();
		} else {
			this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
		}
	}

	getMailingEmails(): void {
		this.mailingEmailService.getMailingEmails().subscribe(
			(data: any) => {
				this.dataSource.data = data as IMailingEmailElement[];
			},(error) => {
				console.error(error);
			}, () => {
				this.loading = false;
			}
		);
	}

	openModal(data?: any): void {
		this.dialog.open(MailingEmailsComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe(() => {
			this.getMailingEmails();
		});
	}
	
	enviarCampana(id:number) : void {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de enviar esta Campaña ahora?')
		.afterClosed().subscribe(res => {
		  if (res) {
			this.mailingEmailService.enviarCampana(id).subscribe((data: any) => {
			  if(data.responseStatus == 'OK'){	
			  	this.notificationService.message(':: '+data.message, 'success');
			  }else{
				this.notificationService.message(':: '+data.message, 'warn');
			  }
			}, (error) => {
			  console.error(error);
			}, () => { this.ngOnInit(); });
		  }
		});
	}

	enviarCampanaTest(id:number) : void {
		this.dialogConfirm.openConfirmDialog('¿Quieres Enviar un Test de esta Campaña ahora?')
		.afterClosed().subscribe(res => {
		  if (res) {
			this.mailingEmailService.enviarCampanaTest(id).subscribe((data: any) => {
			  if(data.responseStatus == 'OK'){	
			  	this.notificationService.message(':: '+data.message, 'success');
			  }else{
				this.notificationService.message(':: '+data.message, 'warn');
			  }
			}, (error) => {
			  console.error(error);
			}, () => { this.ngOnInit(); });
		  }
		});
	}

	anularCampana(id:number) : void {
		this.dialogConfirm.openConfirmDialog('¿Quieres anular esta Campaña ahora?')
		.afterClosed().subscribe(res => {
		  if (res) {
			this.mailingEmailService.anularCampana(id).subscribe((data: any) => {
			  if(data.responseStatus == 'OK'){	
			  	this.notificationService.message(':: '+data.message, 'success');
			  }else{
				this.notificationService.message(':: '+data.message, 'warn');
			  }
			}, (error) => {
			  console.error(error);
			}, () => { this.ngOnInit(); });
		  }
		});
	}
	
	
	/** Filter rows matches. */
	applyFilter(filterValue: string): void {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

}
