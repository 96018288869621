import { Component, OnInit } from '@angular/core';
import { InformationSubmenuService } from 'src/app/shared/services/information-submenu.service';
import { MatDialogRef } from '@angular/material';
import { SubmenuInformationService } from 'src/app/shared/services/submenu-information.service';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-information-submenu',
  templateUrl: './information-submenu.component.html',
  styleUrls: ['./information-submenu.component.scss']
})
export class InformationSubmenuComponent implements OnInit {

  public loading: boolean;
  public activated: boolean;
  public submitted: boolean;
  public folder: string;
  public ckeConfig: any;
  public idComponent: number;
  public nameComponent = 'Información';

  constructor(
    public dialogRef: MatDialogRef<InformationSubmenuComponent>,
    private notificationService: NotificationsService,
    public subMenuInformationService: SubmenuInformationService,
    private resourcesService: ResourcesService
  ) {
    this.submitted = false;
    this.idComponent = this.subMenuInformationService.registerForm.get('id').value;
  }

  ngOnInit() {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'about', groups: [ 'about' ] },
        { name: 'styles', groups: [ 'styles' ] }
      ],
      removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteText,PasteFromWord,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Underline,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Anchor,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,TextColor,Maximize,ShowBlocks,About,Styles,Format,Font,FontSize,Scayt',
      removeDialogTabs: 'link:advanced'
    };
  }

  get f() { return this.subMenuInformationService.registerForm.controls; }

  onSubmit() {
    if (this.subMenuInformationService.registerForm.valid) {
      if (!this.subMenuInformationService.registerForm.get('id').value) {
        this.subMenuInformationService.store(this.subMenuInformationService.registerForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.subMenuInformationService.update(this.subMenuInformationService.registerForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  onFileInput(fileInput: any, $event: any, folder: string) {
    this.folder = folder;
    $event.preventDefault();
    fileInput.click();
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    if (this.folder == 'images') {
      this.subMenuInformationService.registerForm.controls.image.setValue(inputNode.files[0].name);
    } else {
      this.subMenuInformationService.registerForm.controls.logo.setValue(inputNode.files[0].name);
    }

    this.resourcesService.upload(inputNode.files[0], this.folder).subscribe((data: any) => {
      console.log(data);
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  onChange($event: any) {
    this.activated = $event.checked;
    console.log(this.activated);
  }

  onClose(confirmation: boolean) {
    this.subMenuInformationService.registerForm.reset();
    this.dialogRef.close(confirmation);
  }

}
