import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {CustomURLEncoder} from '../../urlencoder/component';

import * as _moment from 'moment';
import 'moment/locale/es';

const moment = _moment;
moment.locale('es');

@Injectable({
  providedIn: 'root'
})
export class RifaService {

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) { }

  informationForm: FormGroup = this.formBuilder.group({
    id_rifa: null,
    nombre: ['', Validators.required],
    titulo: ['', Validators.required],
    subtitulo: [''],
    imagen: ['', Validators.required],
    condiciones_legales:  ['', Validators.required],
    fondo: ['', Validators.required],
    icono_animado: ['', Validators.required],
    fondo_modal : ['', Validators.required],
    cantidad_numeros: ['', Validators.required],
    numeros_maximos: ['', Validators.required],
    estado: [true],
    costo: [],
    cantidad: [],
    fecha_creacion:  moment(),
    id_scsubmenu: ['', Validators.required]
  });

  getRifa(id:number) {
    return this.httpClient.get('/copecbff/admin/rifas/'+id);
  }

  getInformations() {
    return this.httpClient.get('/copecbff/admin/rifas');
  }

  edit(data: any) {
    this.informationForm.setValue(data);
  }

  store(data: any) {
    console.log(data);

    const costtot: any = document.querySelector('#costo');
    const canttot: any = document.querySelector('#cantidad');



    const body = new HttpParams({encoder: new CustomURLEncoder()})

      .set('nombre', data.nombre)
      .set('titulo', data.titulo)
      .set('subtitulo', data.subtitulo)
      .set('imagen', '/resource/images/'+data.imagen)
      .set('fondo', '/resource/images/'+data.fondo)
      .set('icono_animado', '/resource/images/'+data.icono_animado)
      .set('cantidad_numeros', data.cantidad_numeros)
      .set('numeros_maximos', data.numeros_maximos)
      .set('estado', data.estado)
      .set('fecha_creacion', data.fecha_creacion)
      .set('id_scsubmenu', data.id_scsubmenu.id)
      .set('fondo_modal', '/resource/images/'+data.fondo_modal)
      .set('cantidad', canttot.value)
      .set('costo', costtot.value)
      .set('condiciones_legales', data.condiciones_legales);

    return this.httpClient.post<any>(
      '/copecbff/admin/rifas',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any) {
    console.log(data);

    const costtot: any = document.querySelector('#costo');
    const canttot: any = document.querySelector('#cantidad');

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre', data.nombre)
      .set('titulo', data.titulo)
      .set('subtitulo', data.subtitulo)
      .set('imagen', '/resource/images/'+data.imagen)
      .set('fondo', '/resource/images/'+data.fondo)
      .set('icono_animado', '/resource/images/'+data.icono_animado)
      .set('cantidad_numeros', data.cantidad_numeros)
      .set('numeros_maximos', data.numeros_maximos)
      .set('estado', data.estado)
      .set('fecha_creacion', data.fecha_creacion)
      .set('id_scsubmenu', data.id_scsubmenu.id)
      .set('fondo_modal', '/resource/images/'+data.fondo_modal)
      .set('cantidad', canttot.value)
      .set('costo', costtot.value)
      .set('condiciones_legales', data.condiciones_legales);
    return this.httpClient.put<any>(
      '/copecbff/admin/rifas/' + data.id_rifa,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
  }

  delete(id: number) {
    return this.httpClient.delete('/copecbff/admin/rifas/' + id);
  }

  upload(file: any, folder: string) {
    console.log(file);
    // const params = new HttpParams().set('folder', folder);
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('folder', 'images');

    return this.httpClient.post<any>(
      '/copecbff/admin/resources',
      formData);
  }
}
