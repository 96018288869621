import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompanyService } from 'src/app/shared/services/company.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { MatDialogRef } from '@angular/material';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { CategoryService } from '../../shared/services/category.service';
import { ExchangesTypeService } from 'src/app/shared/services/exchanges-type.service';
import { TagsService } from 'src/app/shared/services/tags.service';
import { Observable, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss']
})
export class CreateCompanyComponent implements OnInit, OnDestroy {

  public loading: boolean;
  public estado: boolean;
  public exchange: boolean;
  public disabled: boolean;
  public submitted: boolean;
  public selected: boolean;
  public categories: any;
  public exchanges: any;
  public tags$: Observable<any>;
  public selectedTag: any;
  public isReadonly = true;
  public ckeConfig: any;
  public subscription: Subscription;
  public idComponent: number;
  public nameComponent = 'Empresa';
  public reset: boolean;

  constructor(
    public dialogRef: MatDialogRef<CreateCompanyComponent>,
    private notificationService: NotificationsService,
    public companyService: CompanyService,
    private resourcesService: ResourcesService,
    private categoryService: CategoryService,
    private exchangesTypeService: ExchangesTypeService,
    private tagService: TagsService
  ) {
    this.estado = true;
    //this.exchange = this.companyService.companyForm.get('exchange').value;
    this.disabled = true;
    this.idComponent = this.companyService.companyForm.get('id').value;
    this.reset = this.companyService.companyForm.get('id').value ? true : false;
  }

  ngOnInit() {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'about', groups: [ 'about' ] },
        { name: 'styles', groups: [ 'styles' ] }
      ],
    };
  }

  onSubmit() {
    if (this.companyService.companyForm.valid) {
      if (!this.companyService.companyForm.get('id').value) {
        this.subscription = this.companyService.store(this.companyService.companyForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Guardado satisfactoriamente', 'success');
          this.onClose(true);
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('Client-side error');
          } else {
            console.log('Server-side error');
          }
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.subscription = this.companyService.update(this.companyService.companyForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  get f() { return this.companyService.companyForm.controls; }

  onFileInput(fileInput: any, $event: any) {
    $event.preventDefault();
    fileInput.click();
    
  }

 onFileSelected() {

   var reader = new FileReader();
   //reader.readAsDataURL(file);
    const inputNode: any = document.querySelector('#file');
    const imagen_alianza: any = document.querySelector('#imagen_alianza');
    const imagen_alianza_nombre: any = document.querySelector('#imagen');
    
    imagen_alianza_nombre.value =  inputNode.files[0].name;

   var id = this.companyService.companyForm.get('id').value;

    var formData = new FormData(); 
    formData.append("file", inputNode.files[0], inputNode.files[0].name); 
       
   this.companyService.upload_file(id, formData).subscribe((res)=>{
      console.log(res);
    });

   /*
   reader.onload = function () {
     console.log(reader.result);
    imagen_alianza.value = reader.result;
    this.companyService.companyForm.controls.imagen_alianza.setValue(reader.result);
     return reader.result;
   };*/
   reader.onerror = function (error) {
     console.log('Error: ', error);
   };
}

  onFileSelected2() {
    //const inputNode: any = document.querySelector('#file');
    //this.companyService.companyForm.controls.imagen_alianza.setValue(getBase64());

  }

  onChange($event: any) {
    this.estado = $event.checked;
  }

  onExchange($event: any) {
    this.exchange = $event.checked;
  }

  onClose(confirmation: boolean) {
    this.companyService.companyForm.reset(this.companyService.initialValues);
    this.dialogRef.close(confirmation);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
