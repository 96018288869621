import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CustomURLEncoder} from '../../urlencoder/component';

import * as _moment from 'moment';
import 'moment/locale/es';

const moment = _moment;
moment.locale('es');

@Injectable({
  providedIn: 'root'
})
export class CategoryproductService {

  productForm: FormGroup;
  tags: Array<number>;
  initialValues: any;

  constructor(
    protected httpClient: HttpClient,
    public formBuilder: FormBuilder
  ) {
    this.productForm = this.formBuilder.group({
      id_categoria_producto: null,
      nombre: ['', Validators.required],
      imagen: ['', Validators.required],
      fecha_creacion: ['', Validators.required]
    });
    this.initialValues = this.productForm.value;
  }


  getProducts(id_categoria_producto?: number) {
    if (!id_categoria_producto) {
      return this.httpClient.get('/copecbff/admin/categoriaproducto/');
    } else {
      return this.httpClient.get('/copecbff/admin/categoriaproducto/' + id_categoria_producto);
    }
  }
}
