import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'categoriaRecurso'})
export class CategoriaRecurso implements PipeTransform {

  categoria : String;


  transform(value: any): string {
    function indicarCategoria(str) {
      var texto = '';
      switch(str){
        case 1:
          texto = 'Sin Categoría';
          break;
          case 2:
            texto = 'Sin Categoría';
          break;  
        case 3:
          texto = 'Anexo'; 
          break;
          case 4:
            texto = 'Imagen';  
          break;
          case 5:
            texto = 'Imagen con Link';  
          break;
          default:
            break;

      }
      return texto; 
   }
    return indicarCategoria(value);
  }
}