import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { PopupService } from '../../shared/services/popup.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { PopupComponent } from '../popup/popup.component';
import { NotificationsService } from '../../shared/services/notifications.service';
import { ResourceListComponent } from '../../resources/resource-list/resource-list.component';
import { IPopupElement } from 'src/app/shared/interfaces/ipopup-element';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-popup-list',
  templateUrl: './popup-list.component.html',
  styleUrls: ['./popup-list.component.scss']
})
export class PopupListComponent implements OnInit {

  public displayedColumns: string[] = [
    'id_pop_up',
    'imagen',
    'link',
    'activo',
    'actions'
  ];

  loading: boolean;
  dataSource = new MatTableDataSource<IPopupElement>();
  selection = new SelectionModel<IPopupElement>(true, []);

  imageToShow: any;
  isImageLoading: boolean;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private popupService: PopupService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getPopups();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
   }

  getPopups(): void {
    this.popupService.getPopups().subscribe((data: any) => {
      this.dataSource.data = data as IPopupElement[];
    }, (error) => {
      console.error(error);
    }, () => { this.loading = false; });
  }


  create(): void {
    this.dialog.open(PopupComponent, {
      width: '60%',
      height: '85%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe(() => {
      this.getPopups();
    });
  }

  edit(row: any): void {

    const data = {
      id_pop_up: row.id_pop_up,
      imagen: row.imagen,
      link: row.link,
      activo: row.activo,
      
    };
    console.log(data);
    this.popupService.edit(data);
    this.create();
  }



  resourcePopup(row: any) {
    this.dialog.open(ResourceListComponent, {
      width: '60%',
      autoFocus: true,
      disableClose: true
    });
  }



  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  
}
