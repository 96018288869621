import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { TicketsService } from '../../shared/services/tickets.service';
import { CategoryproductService } from 'src/app/shared/services/categoryproduct.service';
import { IBenefitElement } from 'src/app/shared/interfaces/ibenefit-element';
import { CreateTicketComponent } from '../create-ticket/create-ticket.component';
import { JwtService } from 'src/app/shared/services/jwt.service';
import * as Chart from 'chart.js'


@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {

  loading: boolean;
  dataSource = new MatTableDataSource<IBenefitElement>();
  selection = new SelectionModel<any>(true, []);
  datatickets = new Array();


  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public displayedColumns: string[] = [
    'nombre',
    'stock',
    'canjeados',
    'cantida_maxima_canje',
    'categoria_producto'
  ];
  



  constructor(
    protected ticketService: TicketsService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    private categproductService: CategoryproductService,
    protected jwtService: JwtService
  ) { }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getTickets();
     
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }

   
  }


  getTickets() {
    this.ticketService.getTickets().subscribe((data: any) => {
      this.datatickets = data;
      data.forEach( ( x ) => {
        console.log(x.nombre);
        this.addTitle(x.nombre);
        x.productos.forEach( ( item ) => {
          console.log(item.id_producto, item.nombre, item.precio, item.stock, item.canjeados, item.precio);
          this.grafico(item.id_producto, item.canjeados, item.stock, item.nombre);
        });

      });


     
    }, (error: any) => {
      console.error(error);
    }, () => { 
      $("canvas").css("display", "inline");
      $("canvas").addClass("mat-card");
       
      this.loading = false; });
  }

  ngAfterContentInit() {
    console.log(this.dataSource.data);
    //alert("render");

  }

  addTitle(title) {
    var texto = document.createElement("h2");
    texto.append(title);
    var specContainer = document.getElementById('container') as HTMLElement;
    specContainer.appendChild(texto);
    specContainer.appendChild(  document.createElement("hr"));


  }

  grafico(id, quemados, stock, titulo) {
    
    var color = '';
    if (stock == 0)
      color= "rgba(236,14,24,0.5)";
    if (stock > 100)
      color= "rgba(236,14,24,0.5)";
    if (stock <= 100)
      color= "rgba(61,136,17,0.5)";

    var canvas = document.createElement("canvas");
    canvas.id = id;

    var specContainer = document.getElementById('container') as HTMLElement;
    specContainer.appendChild(canvas);
    //2555 250 156
    //var canvas = document.getElementById(id) as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
      var myChart = new Chart(ctx, {
        type: 'pie',
      data: {
        labels: ["Quemados", "Stock"],
        datasets: [{
          label: "Totales",
          data: [ quemados, stock],
          backgroundColor: [color,' rgba(200,200,200,0.5)'],
          borderWidth: 1
        }]
      },
        options: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: titulo,
            position: 'top',
            fontSize: 16,
            padding: 20
          },

          cutoutPercentage: 70,
          responsive: false,
          display:true,
        }
      });  
  }

  create() {
    this.dialog.open(CreateTicketComponent, {
      width: '60%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.getTickets();
      }
    });
  }



  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id_usuario_cms + 1}`;
  }

}
