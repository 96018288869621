import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filterBy'})
export class FilterByPipe implements PipeTransform {
 transform(items: any[], filters:{[key: string]: string}): any[] {
    return items.reduce((accumulator, currentValue) => {
      for(let field in filters) {
        if(filters[field].includes(currentValue[field]) && !accumulator.includes(currentValue)) {
          return accumulator.concat([currentValue]);
        }
      }
      return accumulator;
    }, []);
  }
}
