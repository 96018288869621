import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { ItemService } from '../../shared/services/item.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.scss']
})
export class CreateItemComponent implements OnInit {

  public loading: boolean;
  public status: boolean;
  public submitted: boolean;
  public reset: boolean;
  public idComponent: number;
  public nameComponent = 'Rubro';

  constructor(
    public dialogRef: MatDialogRef<CreateItemComponent>,
    private notificationService: NotificationsService,
    public itemService: ItemService,
    private resourcesService: ResourcesService,
    private dialog: MatDialog
  ) {
    this.submitted = false;
    this.loading = false;
    this.idComponent = this.itemService.registerForm.get('id').value;
  }

  ngOnInit() {
    this.reset = this.itemService.registerForm.get('id').value;
  }

  get f() { return this.itemService.registerForm.controls; }

  onSubmit() {
    if (this.itemService.registerForm.valid) {
      if (!this.itemService.registerForm.get('id').value) {
        this.itemService.store(this.itemService.registerForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.itemService.update(this.itemService.registerForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  onClose(confirmation: boolean) {
    this.itemService.registerForm.reset();
    this.dialogRef.close(confirmation);
  }

}
