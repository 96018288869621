import { Component, OnInit, ViewChild } from '@angular/core';
import { ItemService } from '../../shared/services/item.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { IItemElement } from 'src/app/shared/interfaces/iitem-element';
import { SelectionModel } from '@angular/cdk/collections';
import { CreateItemComponent } from '../create-item/create-item.component';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit {

  loading: boolean;
  dataSource = new MatTableDataSource<IItemElement>();
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public displayedColumns: string[] = [
    'codigo',
    'nombre',
    // 'estado',
    'actions'
  ];

  constructor(
    private itemService: ItemService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) { this.loading = true; }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getItems();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getItems(): void {
    this.itemService.getItems().subscribe((data: any) => {
      this.dataSource.data = data as IItemElement[];
    }, (error) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  create() {
    this.dialog.open(CreateItemComponent, {
      width: '60%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.getItems();
      }
    });
  }

  edit(row: any) {
    const data = {
      id: row.id_rubro,
      code: row.codigo,
      name: row.nombre
    };
    this.itemService.edit(data);
    this.create();
  }

  delete(id: number) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
      if (res) {
        this.loading = true;
        this.itemService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => { this.getItems(); });
      }
    });
  }

  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
