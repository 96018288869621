import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { IVotacionesElement } from '../../shared/interfaces/ivotaciones-element';

// import { Observable } from 'rxjs';
import { CustomURLEncoder } from '../../urlencoder/component';


@Injectable({
  providedIn: 'root'
})


export class VotacionesService{
  votacion :IVotacionesElement;
  private urlEndPoint: string ='/copecbff/admin/votaciones';
  videos: Array<number>;
  initialValues: any;
  /*private httpHeaders = new HttpHeaders({'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'});*/


  constructor(protected httpClient: HttpClient){}
  /* Obtener votacion o todas las votaciones */
    getVotaciones(id?: number) {
        if (!id) {
          return this.httpClient.get(this.urlEndPoint);
        } else {
          return this.httpClient.get(this.urlEndPoint + '/' + id);
        }
      }

      getVideos(id: number) {
        return this.httpClient.get(this.urlEndPoint + '/' + id + '/videos');
      }

      /* Obtiene los votos de un video de una votación */
        getVideoVotos(id: number, idVideo:number) {
              return this.httpClient.get(this.urlEndPoint+'/'+id+'/videos/'+idVideo+'/votos');
          }




      create(data: any) {
        this.videos = [];

    /* Validamos si hay videos para pasar de ser así por cada video rescatamos el id
     y lo guardamos en el array Number 'videos' */
        if (Array.isArray(data.listVideos)) {
          data.listVideos.forEach((value: any, key: any) => {
            this.videos.push(value.id);
          });
        }
    /* Creamos nuestros parametros que le enviaremos al back */
        const body = new HttpParams({encoder: new CustomURLEncoder()})
          .set('nombre', data.nombre)
          .set('estado', (data.estado) ? '1' : '0')
          .set('descripcion', data.descripcion)
          .set('votosPorCustomer', data.votosPorCustomer)
          .set('tipo', data.tipoVotacion)
          .set('listIdVideos', this.videos.join(','));
        return this.httpClient.post<any>(
          this.urlEndPoint,
          body.toString(),
          { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
        );
      }



      update(data: any) {
        this.videos = [];

    /* Validamos si hay videos para pasar de ser así por cada video rescatamos el id
     y lo guardamos en el array Number 'videos' */
        if (Array.isArray(data.listVideos)) {
          data.listVideos.forEach((value: any, key: any) => {
            this.videos.push(value.id);
          });
        }
        const body = new HttpParams({encoder: new CustomURLEncoder()})
          .set('nombre', data.nombre)
          .set('descripcion', data.descripcion)
          .set('estado', (data.estado) ? '1' : '0')
          .set('votosPorCustomer', data.votosPorCustomer)
          .set('tipo', data.tipoVotacion)
          .set('listIdVideos', this.videos.join(','));
        return this.httpClient.put<any>(
          this.urlEndPoint + '/' + data.$key,
          body.toString(),
          { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
        );
      }


      delete(id: number) {
        return this.httpClient.delete(this.urlEndPoint + '/' + id);
      }

}
