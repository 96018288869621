import { Component, OnInit, ViewChild } from '@angular/core';
import { SitiosService } from '../../shared/services/sitios.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { SitiosComponent } from '../sitios/sitios.component';
import { NotificationsService } from '../../shared/services/notifications.service';
import { ISitiosElement } from 'src/app/shared/interfaces/isitios-element';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-sitios-list',
  templateUrl: './sitios-list.component.html',
  styleUrls: ['./sitios-list.component.scss']
})
export class SitiosListComponent implements OnInit {

	public displayedColumns: string[] = [
		'nombre',
    'fecha_inicio',
    'fecha_fin',
		'especialidad',
		'activo',
		'actions'
	];

	
	loading: boolean;
	dataSource = new MatTableDataSource<ISitiosElement>();
	selection = new SelectionModel<ISitiosElement>(true, []);

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

	constructor(
		private sitiosService: SitiosService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService
	) {
		this.loading = true;
	}

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getSites();
		} else {
			this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
		}
	}

	getSites(): void {
		this.sitiosService.getSites().subscribe(
			(data: any) => {
				this.dataSource.data = data as ISitiosElement[];
			},(error) => {
				console.error(error);
			}, () => {
				this.loading = false;
			}
		);
	}

	openModal(data?: any): void {
		this.dialog.open(SitiosComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe(() => {
			this.getSites();
		});
	}

	delete(id:number): void {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.loading = true;
				this.sitiosService.delete(id).subscribe((data: any) => {
					this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
				}, (error) => {
					console.error(error);
				}, () => {
					this.getSites();
				});
			}
		});
	}

	/** Filter rows matches. */
	applyFilter(filterValue: string): void {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
