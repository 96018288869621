import { Component, OnInit, OnDestroy, Inject, Optional } from '@angular/core';
import { Observable, Subject, of, concat, Subscription } from 'rxjs';
import { NotificationsService } from '../../shared/services/notifications.service';
import { VotacionesService } from '../../shared/services/votaciones.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { IVideosElement } from 'src/app/shared/interfaces/ivideos-element';
import { VideosService } from 'src/app/shared/services/videos.service';

/* Interfaz para Tipo Votacion*/
interface TipoVotacion {
valor: number;
valorVista: string;
}


@Component({
  selector: 'app-votaciones',
  templateUrl: './votaciones.component.html',
  styleUrls: ['./votaciones.component.scss']
})

export class VotacionesComponent implements OnInit {
  public idComponent: number;
	public nameComponent = 'Votacion';
	public registerForm: FormGroup;
	public loading: boolean;
	public activo : boolean;
	public submitted: boolean;
  public selectedVideo : any;
  public videoLoading = false;
  public videoInput$ = new Subject<string>();
  public video: IVideosElement;
  public video$: Observable<any>;
  public tiposVotacion: TipoVotacion[] = [
  {valor: 1, valorVista: 'Votación Vídeos'}
  ];
  public selected: boolean;
  public initialValues: any;
  public ckeConfig: any;
  constructor(
    public dialogRef: MatDialogRef<VotacionesComponent>,
    public videosService :  VideosService,
    private notificationService: NotificationsService,
    public votacionesService: VotacionesService,
    private formBuilder: FormBuilder,
    protected jwtService: JwtService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    this.activo = false;
    this.loading = false;
    }

  ngOnInit(){
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'about', groups: [ 'about' ] },
        { name: 'styles', groups: [ 'styles' ] }
      ],
      removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteText,PasteFromWord,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Underline,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Anchor,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,TextColor,Maximize,ShowBlocks,About',
      removeDialogTabs: 'link:advanced'
      };

    if (this.data == null) {
      /* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando un sitio */
      this.registerForm = this.formBuilder.group({
        $key: null,
        nombre: '',
        votosPorCustomer: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
        activo: [false],
        descripcion:['', Validators.required],
        listVideos:Array(),
        tipoVotacion: ['', [Validators.required]]
      //fechaInicio: null,
      //fechaFin: null,
      //orden: 1,
      });
    } else {
      /* Si al abrir el modal (iniciar el componente) el parámetro no es nulo, se está editando una encuesta */
      /* Se setea el idComponent para que FormToolbarComponent sepa que se está editando */
      this.idComponent = this.data.id;
      /* Se llena la información del formulario */
      /* Se da formato a las fechas */
  //    let initDate = this.data.fechaInicio.split(' ');
  //    let endDate = this.data.fechaFin.split(' ');
      /* Se registra el formulario */
        this.registerForm = this.formBuilder.group({
        $key: this.data.id,
        nombre: this.data.nombre,
        votosPorCustomer: this.data.votosPorCustomer,
        activo: this.data.estado,
        descripcion: this.data.descripcion,
        listVideos: (Array.isArray(this.data.listVideos)) ? this.data.listVideos : Array(),
        tipoVotacion: this.data.tipo
      //fechaInicio: initDate[0] + 'T' + initDate[1],
      //fechaFin: endDate[0] + 'T' + endDate[1],
      //orden: this.data.orden,
      });
    }

    /* Obtiene todos los videos de la votación */
      if(this.idComponent!=null){
        this.votacionesService.getVideos(this.idComponent).subscribe((res: any) => {
          this.selectedVideo = res;
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observador obtuvo una notificación.');
        });
      }
      this.video$ = this.videosService.getVideos();



  }


  onSubmit() {
    if (this.registerForm.valid) {
      if (!this.registerForm.get('$key').value) {
          this.votacionesService.create(this.registerForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notificationn en el onSubmit');
        });
        } else {
          this.votacionesService.update(this.registerForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification en el onSubmit');
        });
      }
    }
  }

  onClose(confirmation: boolean) {
    this.registerForm.reset();
    this.dialogRef.close(confirmation);
  }
}
