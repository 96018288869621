import { Component, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort} from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from '../../shared/services/notifications.service';
import { INoticiasElement } from 'src/app/shared/interfaces/inoticias-element';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { Location } from '@angular/common';
import { NoticiasService } from 'src/app/shared/services/noticias.service';
import { CreateNoticiasComponent } from '../create-noticias/create-noticias.component';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss']
})
export class NoticiasComponent implements OnInit {

  loading: boolean;
  hidden : boolean;
  dataSource = new MatTableDataSource<INoticiasElement>();
  selection = new SelectionModel<INoticiasElement>(true, [])


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private activeRouter: ActivatedRoute,
    private location: Location,
    protected noticiasService: NoticiasService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) {
      this.loading = true;
  }

  public displayedColumns: string[] = [
    'titulo',
    'fechaCreacion',
    'fechaModificacion',
    'link',
    'estado',
    'actions'
  ];

  ngOnInit() {
    /* Se revisa el token de sessión */
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getNoticias();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }





  }

  getNoticias() {
    this.activeRouter.queryParams.subscribe(params => {
    this.hidden = (params.id) ? true : false;
    this.noticiasService.getNoticias(params.id, params.contenido).subscribe((data: any) => {
    this.dataSource.data = data as INoticiasElement[];
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
      });
  }

  openModal(data ? : any) : void {
      this.dialog.open(CreateNoticiasComponent, {
        width: '60%',
        height: '85%',
        autoFocus: true,
        disableClose: true,
        data : data
      }).afterClosed().subscribe(() => {
       //   this.loading = true;
       //   this.getNoticias();
      });
    }

  delete(id: number) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?')
    .afterClosed().subscribe(res => {
      if (res) {
        this.noticiasService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => { this.ngOnInit(); });
      }
    });
  }


  backTo() {
    localStorage.removeItem('id_contenido');
    this.location.back();
  }

  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: INoticiasElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }




}
