import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'EstadosCampanasMailingEmailsPipe'})
export class EstadosCampanasMailingEmailsPipe implements PipeTransform {
  public estado : String;
  transform(value: any): String {
    switch(value) {
      case 0:
        this.estado = 'Cancelado';
        break;
      case 1:
        this.estado = 'Anulado';
        break;
      case 2:
        this.estado = 'Enviado';
        break;
      case 3:
        this.estado = 'Creado';
        break;
      case 4:
        this.estado = 'Programado';
        break;
      case 5:
        this.estado = 'Preparado';
        break;
      default:
      break;
    }
    return this.estado;
  }
}