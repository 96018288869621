import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { MatTableDataSource, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ProductService } from '../../shared/services/product.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CreateProductComponent } from '../create-product/create-product.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { IProductElement } from 'src/app/shared/interfaces/iproduct-element';

import * as _moment from 'moment';
import 'moment/locale/es';
import { JwtService } from 'src/app/shared/services/jwt.service';

const moment = _moment;
moment.locale('es');

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  loading: boolean;
  hidden: boolean;
  dataSource = new MatTableDataSource<IProductElement>();
  selection = new SelectionModel<IProductElement>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public displayedColumns: string[] = [
    // 'select',
    'nombre',
    'titulo',
    'orden',
    'fecha_fin',
    'estado',
    'actions'
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    public productService: ProductService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    private location: Location,
    protected jwtService: JwtService
  ) { this.loading = true; }

  ngOnInit(): void {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getProducts();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getProducts() {
    this.activeRouter.queryParams.subscribe(params => {
      this.hidden = (params.id) ? true : false;
      this.productService.getProducts(params.id, params.submenu).subscribe((data: any) => {
        this.dataSource.data = data as IProductElement[];
      }, (error: any) => {
        console.error(error);
      }, () => { this.loading = false; });
    });
  }

  create() {
    this.dialog.open(CreateProductComponent, {
      width: '60%',
      height: '85%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.getProducts();
      }
    });
  }

  edit(row: any) {

    const imagen = row.imagen.split('/');
    const endDate = row.fecha_fin.split(' ');

    const data = {
      id_producto: row.id_producto,
      nombre: row.nombre,
      orden: row.orden,
      titulo: row.titulo,
      subtitulo: row.subtitulo,
      imagen: imagen[3],
      porcentaje_info: row.porcentaje_info,
      condiciones_comercio: row.condiciones_comercio,
      direcciones: row.direcciones,
      condiciones_legales: row.condiciones_legales,
      precio: row.precio,
      fecha_fin: endDate[0] + 'T' + endDate[1],
      titulo_tipo1: row.titulo_tipo1,
      titulo_tipo2: row.titulo_tipo2,
      codigo: row.codigo,
      estado: (row.estado) ? true : false,
      monitoreado: (row.monitoreado) ? true : false,
      id_categoria_producto: row.id_categoria_producto,
      tags_producto: (Array.isArray(row.tags_producto)) ? row.tags_producto : Array(),
      exchange: row.genera_canje,
      cantida_maxima_canje: row.cantida_maxima_canje,
      id_tipo_canje: row.id_tipo_canje
    };

    this.productService.edit(data);
    this.create();
  }

  delete(id: number) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
      if (res) {
        this.loading = true;
        this.productService.delete(id).subscribe((data: any) => {
            this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
          }, (error: any) => {
            console.error(error);
          }, () => { this.getProducts(); }
        );
      }
    });
  }

  backTo() {
    localStorage.removeItem('id_scsubmenu');
    this.location.back();
  }

  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: IProductElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id_producto + 1}`;
  }
}
