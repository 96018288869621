import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from '../../shared/services/notifications.service';
import { ResourcesService } from '../../shared/services/resources.service';
import { EmailService } from '../../shared/services/email.service';
import { NoticiasService } from '../../shared/services/noticias.service';
import { MailingEmailService } from '../../shared/services/mailing-emails.service';
import { IEmailElement } from '../../shared/interfaces/iemail-element';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { UsersService } from 'src/app/shared/services/users.service';
/* Interfaz para imagenes Miniaturas */
interface Valores {
	valor: string;
	valorVista: string;
	}
@Component({
  selector: 'app-mailing-emails',
  templateUrl: './mailing-emails.component.html',
  styleUrls: ['./mailing-emails.component.scss']
})
export class MailingEmailsComponent implements OnInit {
	public date_fechaEnvio:string;
	public idComponent: number;
	public nameComponent = 'Campañas de Correos';
	public emails: any;
	public aux: any;
	public registerForm: FormGroup;
	public email : IEmailElement;
	public loading: boolean;
	public activo : boolean;
	public submitted: boolean;
	public ckeConfig: any;
	public subscription: Subscription;
	public noticia$: Observable<any>;
	public selectedNoticias: any;
	public noticiaInput$ = new Subject<string>();
	public customer$: Observable<any>;
	public selectedCustomers: any;
	public customerInput$ = new Subject<string>();
	public listaContactos: Valores[] = [
		{valor: '63782', valorVista: 'PRUEBA CMS360'},
		{valor: '63783', valorVista: 'PRUEBA CONECTADOS'},
		{valor: '54497', valorVista: 'CONECTADOS 18 NOV'}
	  ];
	  public listaTiposCampanas: Valores[] = [
		{valor: '1', valorVista: 'Noticias del día: 13:00 hrs de cada día.'},
		{valor: '2', valorVista: 'Noticia de última Hora: cada XX:00 hrs en el día.'}
	  ];


	constructor(
		public dialogRef: MatDialogRef<MailingEmailsComponent>,
		private notificationService: NotificationsService,
		public emailService: EmailService,
		public mailingEmailService: MailingEmailService,
		public noticiasService: NoticiasService,
		private formBuilder: FormBuilder,
		public usersService: UsersService,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.activo = false;
		this.loading = false;
	}

	ngOnInit() {
		if (this.data == null) {
			this.registerForm = this.formBuilder.group({
				idMailingEmail: null,
				asunto: null,
				tipo: 1,
				fechaEnvio: null,
				estado: 0,
				noticias: Array(),
				//customers: Array(),
				activo: [false],
				email: null,
				idContactList:null
			});
			this.selectedNoticias = this.registerForm.get('noticias').value;
		} else {
			this.idComponent = this.data.idMailingEmail;
			/* Se corrige la ruta de la imagen de imagen para cargarla al formulario */
		//	let imagen = this.data.imagen.split('/');
		//	this.data.imagen = imagen[imagen.length - 1];
			this.date_fechaEnvio=this.data.fechaEnvio.split(' ');
			/* Se registra el formulario */
			this.registerForm = this.formBuilder.group({
				idMailingEmail: this.data.idMailingEmail,
				email: this.data.email.idEmail,
				asunto: this.data.asunto,
				tipo: this.data.tipo+'',
				fechaEnvio: this.date_fechaEnvio[0] + 'T' + this.date_fechaEnvio[1],
				estado: this.data.estado,
				activo:this.data.activo,
				idContactList: this.data.idContactList,
				noticias: (Array.isArray(this.data.noticias)) ? this.data.noticias : Array(),
				//customers: (Array.isArray(this.data.customers)) ? this.data.customers : Array()
			});
			this.selectedNoticias = this.data.noticias;
		}
		this.noticia$ = this.noticiasService.getNoticias();
		//this.customer$ = this.usersService.getUsers();
		//this.selectedCustomers = this.registerForm.get('customers').value;
		 // Emails
		this.emailService.getEmails().subscribe((res) => {
			this.aux = res;
			this.emails = _.filter(this.aux, (o) => o.tipo === 2);
			const email = _.find(this.emails, (o) => o.idEmail === this.registerForm.get('email').value);
			this.registerForm.get('email').setValue(email);
		  }, (error: any) => {
			console.error(error);
		  }, () => {
			console.log('Observer got a complete notification');
		  });
	}

	onSubmit() {
		if (this.registerForm.valid) {
			if (!this.registerForm.get('idMailingEmail').value) {
				this.mailingEmailService.store(this.registerForm.value).subscribe((res) => {
					this.submitted = true;
					this.notificationService.message(':: Enviado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error: any) => {
					console.error(error);
				}, () => {
					console.log('Observer got a complete notification');
				});
			} else {
				this.mailingEmailService.update(this.registerForm.value).subscribe((res) => {
					this.submitted = true;
					this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error) => {
					console.error(error);
				}, () => {
					console.log('Observer got a complete notification');
				});
			}
		}
	}

	onFileInput(fileInput: any, $event: any) {
		$event.preventDefault();
		fileInput.click();
	}

  /*


	onFileSelected() {
		const inputNode: any = document.querySelector('#file') as HTMLInputElement;
		this.registerForm.controls.imagen.setValue(inputNode.files[0].name);
		this.resourcesService.upload(inputNode.files[0], 'images').subscribe((data: any) => {
			console.log(data);
		}, (error: any) => {
			console.error(error);
		}, () => {
			this.loading = false;
		});
	}
  */

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}

}
