import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { ViewService } from '../../shared/services/view.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-view',
  templateUrl: './create-view.component.html',
  styleUrls: ['./create-view.component.scss']
})
export class CreateViewComponent implements OnInit, OnDestroy {

  public loading: boolean;
  public status: boolean;
  public responsive: boolean;
  public submitted: boolean;
  public reset: boolean;
  public idComponent: number;
  public nameComponent = 'Vista';
  public subscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<CreateViewComponent>,
    private notificationService: NotificationsService,
    public viewService: ViewService,
    private router: Router
  ) {
    this.status = false;
    this.responsive = false;
    this.submitted = false;
    this.loading = false;
    this.idComponent = this.viewService.registerForm.get('id_view').value;
  }

  ngOnInit() {
    this.reset = this.viewService.registerForm.get('id_view').value;
  }

  get f() { return this.viewService.registerForm.controls; }

  onSubmit() {
    if (this.viewService.registerForm.valid) {
      if (!this.viewService.registerForm.get('id_view').value) {
        this.subscription = this.viewService.store(this.viewService.registerForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
          this.router.navigated = false;
        });
      } else {
        this.subscription = this.viewService.update(this.viewService.registerForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  onChange($event: any) {
    this.status = $event.checked;
  }

  onClose(confirmation: boolean) {
    this.viewService.registerForm.reset();
    this.dialogRef.close(confirmation);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
