import { Component, OnInit, Input } from '@angular/core';
import { ImageService } from '../../services/image.service';
import {
  DomSanitizer,
  SafeStyle
} from '@angular/platform-browser';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit {

  @Input() imageurl: string;
  @Input() px: string;
  imageToShow: any;
  isImageLoading: boolean;
  width: SafeStyle;

  constructor(
    private imageService: ImageService,
    private sanitization: DomSanitizer
  ) {}

  ngOnInit() {
    this.width = `width:${this.px}px`;
    this.isImageLoading = true;
    const route = this.imageurl.split('/');
    const folder = route[2];
    const name = route[3];
    this.imageService.getImage(folder, name).subscribe(data => {
      this.createImageFromBlob(data);
      this.isImageLoading = false;
    }, (error: any) => {
      this.isImageLoading = false;
      console.log(error);
    });
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
   }
}
