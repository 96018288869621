import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { JwtService } from 'src/app/shared/services/jwt.service';

declare var gapi : any;

@Component({
  selector: 'app-rrss',
  templateUrl: './rrss.component.html',
  styleUrls: ['./rrss.component.scss']
})
export class RrssComponent implements OnInit {

  loading: boolean;
  dataSource = new Array();
  selection = new SelectionModel<any>(true, []);


  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;



  constructor(
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) { }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {

    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }


}
