import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _moment from 'moment';
import 'moment/locale/es';
import { IEmailElement } from '../interfaces/iemail-element';

import {CustomURLEncoder} from '../../urlencoder/component';

const moment = _moment;
moment.locale('es');

const PATH: string = "/admin/emails";

@Injectable({
	providedIn: 'root'
})

export class EmailService {

    constructor(
		protected httpClient: HttpClient
	) { }

    getEmails(): Observable<IEmailElement[]> {
		return this.httpClient.get<IEmailElement[]>('/copecbff' + PATH);
	}

    store(data: any) {
		const initDate = moment(data.fechaInicio);
		const endDate = moment(data.fechaFin);
		let estado = data.estado ? "1" : "0" ;
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('asunto', data.asunto)
			.set('html', data.html)
			.set('htmlHead', data.htmlHead)
			.set('htmlFooter', data.htmlFooter)
			.set('tipo', data.tipo)
			.set('estado', estado)
			.set('fecha_inicio', initDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('fecha_fin', endDate.format('YYYY-MM-DD HH:mm:ss'))

		return this.httpClient.post<any>(
			'/copecbff' + PATH,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

    update(data: any) {
		const initDate = moment(data.fechaInicio);
		const endDate = moment(data.fechaFin);

		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('asunto', data.asunto)
			.set('html', data.html)
			.set('htmlHead', data.htmlHead)
			.set('htmlFooter', data.htmlFooter)
			.set('tipo', data.tipo)
			.set('estado', data.estado)
			.set('fechaInicio', initDate.format('YYYY-MM-DD HH:mm'))
			.set('fechaFin', endDate.format('YYYY-MM-DD HH:mm'))

            return this.httpClient.put<any>(
                '/copecbff' + PATH + '/' + data.idEmail,
                body.toString(),
                { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
            );
	}

}