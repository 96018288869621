import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort, MatTableDataSource, MatDialog, MatDialogConfig, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { CreateViewComponent } from './create-view/create-view.component';
import { DialogService } from '../shared/services/dialog.service';
import { ViewService } from '../shared/services/view.service';
import { NotificationsService } from '../shared/services/notifications.service';
import { IViewElement } from '../shared/interfaces/iview-element';
import { JwtService } from '../shared/services/jwt.service';

@Component({
  selector: 'app-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss']
})
export class ViewsComponent implements OnInit {

  public displayedColumns: string[] = [
    // 'select',
    'id_view',
    'name',
    'estado',
    'actions'
  ];

  loading: boolean;
  flat: boolean;
  dataSource = new MatTableDataSource<IViewElement>();
  selection = new SelectionModel<IViewElement>(true, []);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private router: Router,
    protected viewService: ViewService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getViews();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getViews(name?: string): void {
    this.flat = (name) ? true : false;
    this.viewService.getViews(name).subscribe((data: any) => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = data as IViewElement[];
    }, (error) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  create() {
    this.dialog.open(CreateViewComponent, {
      width: '60%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.getViews();
      }
    });
  }

  edit(row: any) {
    const data = {
      id_view: row.id_view,
      name: row.name,
      content: row.content,
      activated: row.estado
    };
    this.viewService.edit(data);
    this.create();
  }

  delete(id: number) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?')
    .afterClosed().subscribe(res => {
      if (res) {
        this.viewService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => {
          this.loading = false;
          this.getViews();
        });
      }
    });
  }

  activate(id: number, name: string) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro que desea activar esta Vista?')
    .afterClosed().subscribe(res => {
      if (res) {
        this.viewService.activate(id).subscribe((data: any) => {
          this.notificationService.message(':: Vista activada con exito', 'success');
        }, (error) => {
          console.error(error);
        }, () => {
          this.loading = false;
          this.getViews(name);
        });
      }
    });
  }

  /* Back to Views */
  back() {
    this.getViews();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: IViewElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id_view + 1}`;
  }
}
