import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { DetailFormatService } from 'src/app/shared/services/detail-format.service';
import { ProductService } from 'src/app/shared/services/product.service';
import * as _ from 'lodash';

export interface Type {
  id: number;
  value: string;
}

export interface Orientation {
  id: number;
  value: string;
}

@Component({
  selector: 'app-create-detail-format',
  templateUrl: './create-detail-format.component.html',
  styleUrls: ['./create-detail-format.component.scss']
})
export class CreateDetailFormatComponent implements OnInit {

  loading: boolean;
  status: boolean;
  submitted: boolean;
  products: any;

  types: Type[] = [
    {id: 1, value: 'Texto'},
    {id: 2, value: 'Link'}
  ];

  orientations: Orientation[] = [
    {id: 1, value: 'Derecha'},
    {id: 2, value: 'Izquierda'}
  ];

  constructor(
    public dialogRef: MatDialogRef<CreateDetailFormatComponent>,
    private notificationService: NotificationsService,
    public detailFormatService: DetailFormatService,
    public productService: ProductService,
    private dialog: MatDialog,
  ) {
    this.status = false;
    this.submitted = false;
    this.loading = false;
  }

  ngOnInit() {
    // Type
    const type = this.types.find(c => c.id === this.detailFormatService.detailFormatForm.get('type').value);
    this.detailFormatService.detailFormatForm.get('type').setValue(type);

    // Orientation
    const orientation = this.orientations.find(c => c.id === this.detailFormatService.detailFormatForm.get('orientation').value);
    this.detailFormatService.detailFormatForm.get('orientation').setValue(orientation);

    // Products
    this.productService.getProducts().subscribe((res) => {
      this.products = res;
      const product = _.find(this.products, (o) => {
        return o.id_producto === this.detailFormatService.detailFormatForm.get('id_product').value;
      });
      this.detailFormatService.detailFormatForm.get('id_product').setValue(product);
    }, (error: any) => {
      console.error(error);
    }, () => {
      console.log('Observer got a complete notification');
    });
  }

  get f() { return this.detailFormatService.detailFormatForm.controls; }

  onSubmit() {
    if (this.detailFormatService.detailFormatForm.valid) {
      if (!this.detailFormatService.detailFormatForm.get('id').value) {
        this.detailFormatService.store(this.detailFormatService.detailFormatForm.value).subscribe((res) => {
          console.log(res);
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose();
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.detailFormatService.update(this.detailFormatService.detailFormatForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose();
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  onClose() {
    this.detailFormatService.detailFormatForm.reset();
    this.dialogRef.close();
  }
}
