import { Component, OnInit, OnDestroy, Inject, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TagsService } from 'src/app/shared/services/tags.service';
import { IZonasEdsElement } from 'src/app/shared/interfaces/izonaseds-element';
import * as _moment from 'moment';
import 'moment/locale/es';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { IRegionElement } from '../../shared/interfaces/iregion-element';
import { RegionService } from '../../shared/services/region.service';
/* Interfaz para opciones */
  interface OpcionesNum {
    valor: number;
    valorVista: string;
    }

  const moment = _moment;
  moment.locale('es');

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})


export class CreateUserComponent implements OnInit, OnDestroy {
  public date_fechaIngresoCopec:string;
  public date_fechaNacimiento:string;
  public date_fechaNacimientoParejaEds:string;
  public loading: boolean;
  public estado: boolean;
  public submitted: boolean;
  public selected: boolean;
  public idComponent: number;
  public folder: string;
  public userForm: FormGroup;
  public nameComponent = 'usuario';
  public subscription: Subscription;
  public tags$: Observable<any>;
  public selectedTag: any;
  public initialValues : any;
  public regions: IRegionElement[];
  public tiposLogin:OpcionesNum[]=[
    {valor: 1, valorVista: 'Login por Auth0'},
    {valor: 2, valorVista: 'Login Interno'}
  ]
  public parejaEds: OpcionesNum[] =[
    {valor: 0, valorVista: 'No'},
    {valor: 1, valorVista: 'Si'}
  ]
  public accesos: OpcionesNum[] =[
    {valor: 1, valorVista: 'Usuario Normal'},
    {valor: 2, valorVista: 'Moderador Avisos'}
  ]
  public zonasEds: IZonasEdsElement[] = [
    {valor: 'SIN-ZONA', valorVista: 'Sin Zona'},
    {valor: 'ZONA-NORTE-1', valorVista: 'Zona Norte 1'},
    {valor: 'ZONA-NORTE-2', valorVista:'Zona Norte 2'},
    {valor: 'ZONA-SANTIAGO-1', valorVista: 'Zona Santiago 1'},
    {valor: 'ZONA-SANTIAGO-2', valorVista: 'Zona Santiago 2'},
    {valor: 'ZONA-SANTIAGO-3', valorVista: 'Zona Santiago 3'},
    {valor: 'ZONA-SANTIAGO-4', valorVista: 'Zona Santiago 4'},
    {valor: 'ZONA-SANTIAGO-5', valorVista: 'Zona Santiago 5'},
    {valor: 'ZONA-SANTIAGO-6', valorVista: 'Zona Santiago 6'},
    {valor: 'ZONA-SANTIAGO-7', valorVista: 'Zona Santiago 7'},
    {valor: 'ZONA-SANTIAGO-8', valorVista: 'Zona Santiago 8'},
    {valor: 'ZONA-SANTIAGO-9', valorVista: 'Zona Santiago 9'},
    {valor: 'ZONA-SANTIAGO-10', valorVista: 'Zona Santiago 10'},
    {valor: 'ZONA-CENTRAL-1', valorVista: 'Zona Central 1'},
    {valor: 'ZONA-CENTRAL-2', valorVista: 'Zona Central 2'},
    {valor: 'ZONA-CENTRAL-3', valorVista: 'Zona Central 3'},
    {valor: 'ZONA-CENTRAL-4', valorVista: 'Zona Central 4'},
    {valor: 'ZONA-CENTRAL-5', valorVista: 'Zona Central 5'},
    {valor: 'ZONA-CENTRAL-6', valorVista: 'Zona Central 6'},
    {valor: 'ZONA-SUR-1', valorVista: 'Zona Sur 1'},
    {valor: 'ZONA-SUR-2', valorVista: 'Zona Sur 2'},
    {valor: 'ZONA-SUR-3', valorVista: 'Zona Sur 3'},
    {valor: 'ZONA-SUR-4', valorVista: 'Zona Sur 4'},
    {valor: 'ZONA-SUR-5', valorVista: 'Zona Sur 5'},
    {valor: 'ZONA-SUR-6', valorVista: 'Zona Sur 6'},
    {valor: 'ZONA-SUR-7', valorVista: 'Zona Sur 7'},
    {valor: 'ZONA-SUR-8', valorVista: 'Zona Sur 8'}
  ];
  
  constructor(
    public dialogRef: MatDialogRef<CreateUserComponent>,
    public tagsService : TagsService,
    public notificationService: NotificationsService,
    public usersService: UsersService,
    public resourcesService :ResourcesService,
    private regionService: RegionService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
  ) {
    this.date_fechaIngresoCopec = moment().toString();
    this.date_fechaNacimiento = moment().toString();
    this.date_fechaNacimientoParejaEds = moment().toString();
    this.userForm = this.formBuilder.group({
      idCustomer: null,
      nombre: ['', Validators.required],
      username: ['', [Validators.required, Validators.minLength(5), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      rut: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(10)]],
      zonaEds:['', Validators.required],
      imagen: '',
      telefono:'',
      acceso:['', Validators.required],
      parejaEds:['', Validators.required],
      nombreParejaEds:null,
      emailParejaEds:null,
      idRegion:['', Validators.required],
      comuna:null,
      direccion:null,
      fechaIngresoCopec:this.date_fechaIngresoCopec[0] + 'T' + this.date_fechaIngresoCopec[1],
      fechaNacimiento:this.date_fechaNacimiento[0] + 'T' + this.date_fechaNacimiento[1],
      fechaNacimientoParejaEds:this.date_fechaNacimientoParejaEds[0] + 'T' + this.date_fechaNacimientoParejaEds[1],
      estado: null,
      tipoLogin:['', Validators.required],
      password:''
      //tags: Array()

    });
    this.estado = true;
    this.idComponent = this.userForm.get('idCustomer').value;
    this.initialValues = this.userForm.value;
  
   }


  ngOnInit() {
    if(this.data!=null){
      /* Validaciones de fecha */
      if(this.data.fechaIngresoCopec!=null){
        this.date_fechaIngresoCopec = this.data.fechaIngresoCopec.split(' ');
       }else{
            this.date_fechaIngresoCopec = moment().toString();
          }
        if(this.data.fechaNacimiento!=null){
          this.date_fechaNacimiento = this.data.fechaNacimiento.split(' ');
        }else{
          this.date_fechaNacimiento = moment().toString();
        }
        if(this.data.fechaNacimientoParejaEds!=null){
        this.date_fechaNacimientoParejaEds = this.data.fechaNacimientoParejaEds.split(' ');
        }else{
          this.date_fechaNacimientoParejaEds = moment().toString();
        }
       /* Asigna valores al form de un registro existente */
        const userExistente = {
          idCustomer: this.data.idCustomer,
          nombre: this.data.nombre,
          username: this.data.username,
          rut: this.data.rut,
          zonaEds:this.data.zonaEds,
          estado: this.data.estado,
          imagen:this.data.imagen,
          telefono: this.data.telefono,
          acceso:this.data.acceso,
          direccion:this.data.direccion,
          comuna:this.data.comuna,
          idRegion:this.data.region.id_region,
          tipoLogin:this.data.tipoLogin,
          password:this.data.password,
          parejaEds:this.data.parejaEds? 1 : 0,
          nombreParejaEds:this.data.nombreParejaEds,
          emailParejaEds:this.data.emailParejaEds,
          fechaIngresoCopec:this.date_fechaIngresoCopec[0] + 'T' + '12:00',
          fechaNacimiento:this.date_fechaNacimiento[0] + 'T' + '13:00',
          fechaNacimientoParejaEds:this.date_fechaNacimientoParejaEds[0] + 'T' + '14:00'
        // tags: (Array.isArray(this.data.tags)) ? this.data.tags : Array() // categorias de avisos
          }
      this.definirTipoLogin(this.data.tipoLogin);  
      this.ingresarParejaEds(this.data.parejaEds);  
      this.userForm.setValue(userExistente);
    }
  // TAGS
 /* this.tags$ = this.tagsService.getTags();
  this.selectedTag = this.userForm.get('tags').value;
*/

		// Regions
		this.regionService.getRegions().subscribe((res) => {
			this.regions = res;
		}, (error: any) => {
			console.error(error);
		}, () => {
		});
} // cierra ngOnInit

/*Crea el user */
  onSubmit() {
    if (this.userForm.valid) {
      /* se valida si hay una ruta de imagen asignada al campo, de lo contrario generamos una */
        if(this.userForm.get('imagen').value==null){
          this.userForm.controls['imagen'].setValue('usercopec.jpg');
        }

      /* Validamos si vamos a crear  o actualizar según ID */
      if (!this.userForm.get('idCustomer').value) {
        this.usersService.store(this.userForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        /*acá actualiza un user */
        this.usersService.update(this.userForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  get f() { return this.userForm.controls; }


  onFileInput(fileInput: any, $event: any, folder: string) {
    this.folder = folder;
    $event.preventDefault();
    fileInput.click();
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
      this.userForm.controls.imagen.setValue(inputNode.files[0].name);

      this.resourcesService.upload(inputNode.files[0], this.folder).subscribe((data: any) => {
        console.log(data);
      }, (error: any) => {
        console.error(error);
      }, () => { this.loading = false; });
    }


    public definirTipoLogin(event){
      if(event==1){
        this.userForm.get('password').disable();
        this.userForm.get('password').clearValidators();
        this.userForm.get('password').updateValueAndValidity();
        this.userForm.get('tipoLogin').updateValueAndValidity();
      }else{
        this.userForm.get('password').enable();
        this.userForm.get('password').setValidators([Validators.required, Validators.minLength(6)])
        this.userForm.get('password').updateValueAndValidity();
        this.userForm.get('tipoLogin').updateValueAndValidity();
      }
    }

   public ingresarParejaEds(event){
     if(event!=1){
         this.userForm.get('nombreParejaEds').disable();
         this.userForm.get('nombreParejaEds').clearValidators();
         this.userForm.get('nombreParejaEds').updateValueAndValidity();
         this.userForm.get('emailParejaEds').disable();
         this.userForm.get('emailParejaEds').clearValidators();
         this.userForm.get('emailParejaEds').updateValueAndValidity();
         this.userForm.get('fechaNacimientoParejaEds').disable();        
     }else{
         this.userForm.get('nombreParejaEds').enable();
         this.userForm.get('nombreParejaEds').setValidators([Validators.required])
         this.userForm.get('nombreParejaEds').updateValueAndValidity();
         this.userForm.get('emailParejaEds').enable();
         this.userForm.get('emailParejaEds').setValidators([Validators.required])
         this.userForm.get('emailParejaEds').updateValueAndValidity();
         this.userForm.get('fechaNacimientoParejaEds').enable();      
     }
  }


  onChange($event: any) {
    this.estado = $event.checked;
  }

  onClose(confirmation: boolean) {
    this.dialogRef.close(confirmation);
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
