import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CustomURLEncoder} from '../../urlencoder/component';

import * as _moment from 'moment';
import 'moment/locale/es';

const moment = _moment;
moment.locale('es');

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  productForm: FormGroup;
  tags: Array<number>;
  initialValues: any;

  constructor(
    protected httpClient: HttpClient,
    public formBuilder: FormBuilder
  ) {
    this.productForm = this.formBuilder.group({
      id_producto: null,
      condiciones_comercio: ['', Validators.required],
      direcciones: ['', Validators.required],
      condiciones_legales: ['', Validators.required],
      fecha_fin: [moment(), Validators.required],
      imagen: ['', Validators.required],
      // likes: '',
      nombre: ['', [Validators.required, Validators.minLength(3)]],
      orden: ['', Validators.required],
      porcentaje_info: '',
      precio: 0,
      subtitulo: '',
      titulo: ['', Validators.required],
      titulo_tipo1: '',
      titulo_tipo2: '',
      codigo: '',
      estado: [false],
      monitoreado: [false],
      id_categoria_producto: ['', Validators.required],
      tags_producto: Array(),
      exchange: [false],
      cantida_maxima_canje: [{value: 0, disabled: false}],
      id_tipo_canje: ['', Validators.required],
      // bullets: this.formBuilder.array([])
      // stock: 0,
      // canjeados: 0
    });
    this.initialValues = this.productForm.value;
  }

  getProducts(id?: number, submenu: boolean = false) {
    if (submenu) {
      return this.httpClient.get('/copecbff/admin/scsubmenus/' + id + '/productos');
    } else {
      if (!id) {
        return this.httpClient.get('/copecbff/admin/productos');
      } else {
        return this.httpClient.get('/copecbff/admin/productos/' + id);
      }
    }
  }

  edit(data: any): void {
    this.productForm.setValue(data);
  }

  store(data: any) {

    this.tags =  [];
    const endDate = moment(data.fecha_fin);

    if (Array.isArray(data.tags_producto)) {
      data.tags_producto.forEach((value: any, key: any) => {
        this.tags.push(value.id_tag_producto);
      });
    }

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('condiciones_comercio', data.condiciones_comercio)
      .set('direcciones', data.direcciones)
      .set('condiciones_legales', data.condiciones_legales)
      .set('fecha_fin', endDate.format('YYYY-MM-DD HH:mm:ss'))
      .set('imagen', '/resource/banner/' + data.imagen)
      .set('nombre', data.nombre)
      .set('orden', data.orden)
      .set('porcentaje_info', data.porcentaje_info)
      .set('precio', data.precio)
      .set('subtitulo', data.subtitulo)
      .set('titulo', data.titulo)
      .set('titulo_tipo1', data.titulo_tipo1)
      .set('titulo_tipo2', data.titulo_tipo2)
      .set('codigo', data.codigo)
      .set('estado', (data.estado) ? '1' : '0')
      .set('monitoreado', (data.monitoreado) ? '1' : '0')
      .set('id_categoria_producto', data.id_categoria_producto.id_categoria_producto)
      .set('cantidad_maxima_canje', data.cantida_maxima_canje)
      .set('id_tipo_canje', data.id_tipo_canje.id_tipo_canje)
      .set('genera_canje', data.exchange)
      .set('id_tag_producto', this.tags.join(','));

    return this.httpClient.post<any>(
      '/copecbff/admin/productos',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any) {

    this.tags = [];
    const endDate = moment(data.fecha_fin);

    if (Array.isArray(data.tags_producto)) {
      data.tags_producto.forEach((value: any, key: any) => {
        this.tags.push(value.id_tag_producto);
      });
    }

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('condiciones_comercio', data.condiciones_comercio)
      .set('direcciones', data.direcciones)
      .set('condiciones_legales', data.condiciones_legales)
      .set('fecha_fin', endDate.format('YYYY-MM-DD HH:mm:ss'))
      .set('imagen', '/resource/banner/' + data.imagen)
      .set('nombre', data.nombre)
      .set('orden', data.orden)
      .set('porcentaje_info', data.porcentaje_info)
      .set('precio', data.precio)
      .set('subtitulo', data.subtitulo)
      .set('titulo', data.titulo)
      .set('titulo_tipo1', data.titulo_tipo1)
      .set('titulo_tipo2', data.titulo_tipo2)
      .set('codigo', data.codigo)
      .set('estado', (data.estado) ? '1' : '0')
      .set('monitoreado', (data.monitoreado) ? '1' : '0')
      .set('id_categoria_producto', data.id_categoria_producto.id_categoria_producto)
      .set('cantidad_maxima_canje', data.cantida_maxima_canje)
      .set('id_tipo_canje', data.id_tipo_canje.id_tipo_canje)
      .set('genera_canje', data.exchange)
      .set('id_tag_producto', this.tags.join(','));

    return this.httpClient.put<any>(
      '/copecbff/admin/productos/' + data.id_producto,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
  }

  delete(id: number) {
    return this.httpClient.delete('/copecbff/admin/productos/' + id);
  }
}
