import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { NavbarService } from 'src/app/shared/services/navbar.service';
import { ITypeElement } from 'src/app/shared/interfaces/itype-element';

@Component({
  selector: 'app-create-menu',
  templateUrl: './create-menu.component.html',
  styleUrls: ['./create-menu.component.scss']
})
export class CreateMenuComponent implements OnInit {
  public loading: boolean;
  public estado: boolean;
  public submitted: boolean;
  public selected: boolean;
  public idComponent: number;
  public nameComponent = 'Menú';

  types: ITypeElement[] = [
    {id: 1, value: 'TIPO LINK'},
    {id: 2, value: 'TIPO CATEGORIA'}
  ];

  conditions = [{
    value: true,
    text: 'Activo'
  }, {
    value: false,
    text: 'Inactivo'
  }];

  constructor(
    public dialogRef: MatDialogRef<CreateMenuComponent>,
    private notificationService: NotificationsService,
    public navBarService: NavbarService
  ) {
    this.estado = true;
    this.idComponent = this.navBarService.menuForm.get('id').value;
   }

  ngOnInit() {
    // Menu Type
    const type = this.types.find(c => c.id === this.navBarService.menuForm.get('tipo').value);
    this.navBarService.menuForm.get('tipo').setValue(type);

    // visibleTop
    const visibleTop = this.conditions.find(c => c.value === this.navBarService.menuForm.get('visibleTop').value);
    this.navBarService.menuForm.get('visibleTop').setValue(visibleTop);

    // visibleOnlyLogin
    const visibleOnlyLogin = this.conditions.find(c => c.value === this.navBarService.menuForm.get('visibleOnlyLogin').value);
    this.navBarService.menuForm.get('visibleOnlyLogin').setValue(visibleOnlyLogin);

    // userElement
    const userElement = this.conditions.find(c => c.value === this.navBarService.menuForm.get('userElement').value);
    this.navBarService.menuForm.get('userElement').setValue(userElement);
  }

  onSubmit() {
    if (this.navBarService.menuForm.valid) {
      if (!this.navBarService.menuForm.get('id').value) {
        this.navBarService.storeMenu(this.navBarService.menuForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.navBarService.updateMenu(this.navBarService.menuForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  get f() { return this.navBarService.menuForm.controls; }

  onChange($event: any) {
    this.estado = $event.checked;
  }

  onClose(confirmation: boolean) {
    this.navBarService.menuForm.reset();
    this.dialogRef.close(confirmation);
  }

}
