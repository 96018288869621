import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { PopupService } from '../../shared/services/popup.service';
import { ResourceListComponent } from 'src/app/resources/resource-list/resource-list.component';
import { ResourcesService } from '../../shared/services/resources.service';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class PopupComponent implements OnInit {

  loading: boolean;
  status: boolean;
  blank : boolean;
  responsive: boolean;
  submitted: boolean;
  idComponent: number;
  public ckeConfig: any;
  nameComponent = ' Popup';

  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    private notificationService: NotificationsService,
    public popupService: PopupService,
    private resourcesService: ResourcesService,
    private dialog: MatDialog,
  ) {
    this.status = false;
    this.submitted = true;
  }

  ngOnInit() {
    var inputNode: any =  this.popupService.registerForm.get('imagen').value;
    inputNode = inputNode.replace('/resource/popup/','');
    this.popupService.registerForm.controls.imagen.setValue(inputNode);

   }

  get f() { return this.popupService.registerForm.controls; }

  onSubmit() {
    if (this.popupService.registerForm.valid) {
      if (!this.popupService.registerForm.get('id_pop_up').value) {
        this.popupService.store(this.popupService.registerForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.popupService.update(this.popupService.registerForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  statusCheck($event: any) {
    //this.activo = $event.checked;
  }

  blankCheck($event: any) {
    this.blank = $event.checked;
  }
  responsiveCheck($event: any) {
    this.responsive = $event.checked;
  }

  onFocus($event: any) {
    $event.preventDefault();
    this.dialog.open(ResourceListComponent, {
      width: '60%',
      autoFocus: true,
      disableClose: true
    });
  }

  onFileInput(fileInput: any, $event: any) {
    $event.preventDefault();
    fileInput.click();
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file') as HTMLInputElement;
    this.popupService.registerForm.controls.imagen.setValue(inputNode.files[0].name);



    this.resourcesService.upload(inputNode.files[0], 'popup').subscribe((data: any) => {
      console.log(data);
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  onClose(confirmation: boolean) {
    this.popupService.registerForm.reset();
    this.dialogRef.close(confirmation);
  }

}
