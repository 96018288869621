import { Component, OnInit, Optional, Inject } from '@angular/core';
import { ContenidosService } from 'src/app/shared/services/contenidos.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from '../../shared/services/notifications.service';
import { Observable, Subject, of, concat, Subscription } from 'rxjs';
import { VideosService } from 'src/app/shared/services/videos.service';
import { NoticiasService } from 'src/app/shared/services/noticias.service';
import { IVideosElement } from 'src/app/shared/interfaces/ivideos-element';
import { INoticiasElement } from 'src/app/shared/interfaces/inoticias-element';
import * as _ from 'lodash';

@Component({
  selector: 'app-create-contenido',
  templateUrl: './create-contenidos.component.html',
  styleUrls: ['./create-contenidos.component.scss']
})

export class CreateContenidosComponent implements OnInit {
  public contenidoForm : FormGroup;
  public loading: boolean;
  public estado: boolean;
  public submitted: boolean;
  public selected: boolean;
  public idComponent: number;
  public nameComponent = 'Contenido';
  public subscription: Subscription;
  public selectedVideo : any;
  public selectedNoticia : any;
  public videoLoading = false;
  public noticiaLoading=false;
  public videoInput$ = new Subject<string>();
  public noticiaInput$=new Subject<string>();
  public video: IVideosElement;
  public video$: Observable<any>;
  public noticia:INoticiasElement;
  public noticia$: Observable<any>;
  public initialValues: any;
  constructor(
    public contenidosService: ContenidosService,
    public videosService: VideosService,
    public noticiasService : NoticiasService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateContenidosComponent>,
    private dialogConfirm: DialogService,
    public notificationService: NotificationsService,
    protected jwtService: JwtService,
    private formBuilder: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
        this.contenidoForm = this.formBuilder.group({
        id: null,
        nombre: ['', [Validators.required, Validators.minLength(3)]],
        descripcion: null,
        orden: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
        estado: [true],
        listVideos:Array(),
        listNoticias: Array()
        });

    this.estado = true;
    this.idComponent = this.contenidoForm.get('id').value;
    //this.initialValues = this.contenidoForm.value;
    }

  ngOnInit() {
    /* Asigna valores al form de un registro existente */
    if(this.data!=null){
      const contenidoExistente={
      id: this.data.id,
      nombre: this.data.nombre,
      descripcion: this.data.descripcion,
      orden: this.data.orden,
      estado: this.data.estado,
      listVideos: (Array.isArray(this.data.listVideos)) ? this.data.listVideos : Array(),
      listNoticias: (Array.isArray(this.data.listNoticias)) ? this.data.listNoticias : Array()
      }
      this.contenidoForm.setValue(contenidoExistente);
      }

    const idContenido = this.contenidoForm.get('id').value;
    const contenido = true;
/* Obtiene todos los videos para  contenido */
  if(idContenido!=null){
    this.contenidosService.getVideos(idContenido).subscribe((res: any) => {
      this.selectedVideo = res;
    }, (error: any) => {
      console.error(error);
    }, () => {
      console.log('Observer got a complete notification');
    });
  }
/* Obtiene todas las noticias del contenido*/
if(idContenido!=null){
    this.contenidosService.getNoticias(idContenido).subscribe((res: any) => {
      this.selectedNoticia = res;
    }, (error: any) => {
      console.error(error);
    }, () => {
      console.log('Observer got a complete notification');
    });
}
    this.video$ = this.videosService.getVideos();
    this.noticia$ = this.noticiasService.getNoticias();


  }


  /* Crea el contenido */
    onSubmit() {
      if (this.contenidoForm.valid) {
        if (!this.contenidoForm.get('id').value) {
          this.contenidosService.create(this.contenidoForm.value).subscribe((res: any) => {
            this.submitted = true;
            this.notificationService.message(':: Enviado satisfactoriamente', 'success');
            this.onClose(true);
          }, (error: any) => {
            console.error(error);
          }, () => {
            console.log('Observer got a complete notification');
          });
        } else {
          /*aca actualiza un video */
          this.contenidosService.update(this.contenidoForm.value).subscribe((res: any) => {
            this.submitted = true;
            this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
            this.onClose(true);
          }, (error) => {
            console.error(error);
          }, () => {
            console.log('Observer got a complete notification');
          });
        }
      }
    }


    get f() { return this.contenidoForm.controls; }



    onChange($event: any) {
      this.estado = $event.checked;
    }

    onClose(confirmation: boolean) {
      this.contenidoForm.reset();
      this.dialogRef.close(confirmation);
    }


    ngOnDestroy(): void {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }


}
