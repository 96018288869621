import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
//import * as AdminLte from 'admin-lte';
import * as AdminLte from 'admin-lte';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() scotiaID: string;
  @Input() role: string;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  logout() {
    localStorage.removeItem('access_token');
    this.router.navigate(['login']);
  }

/* Usamos esta función paracorregir problema en el button toggle del header*/
toggle(): void {
  const portalBody = $('#portalBody');
   if ( portalBody.hasClass( 'sidebar-collapse' )){
      portalBody.removeClass('sidebar-collapse');
       portalBody.addClass('sidebar-open');
      } else {
        portalBody.addClass('sidebar-collapse');
      }
     }
}
