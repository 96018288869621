import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ItemService } from '../../shared/services/item.service';
import * as _ from 'lodash';

export interface Type {
  id: number;
  value: string;
}

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent implements OnInit {

  public loading: boolean;
  public subscription: Subscription;
  public submitted: boolean;
  public ckeConfig: any;
  public reset: boolean;
  public idComponent: number;
  public nameComponent = 'Categoría';
  public items: any;

  types: Type[] = [
    {id: 1, value: 'PRODUCTOS'},
    {id: 2, value: 'RUBROS'}
  ];

  constructor(
    public dialogRef: MatDialogRef<CreateCategoryComponent>,
    private notificationService: NotificationsService,
    public categoryService: CategoryService,
    public itemService: ItemService,
    private resourcesService: ResourcesService,
  ) {
    this.idComponent = this.categoryService.registerForm.get('id').value;
  }

  ngOnInit() {
    this.reset = this.categoryService.registerForm.get('id').value;
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'about', groups: [ 'about' ] },
        { name: 'styles', groups: [ 'styles' ] }
      ],
      removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteText,PasteFromWord,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Underline,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Anchor,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,TextColor,Maximize,ShowBlocks,About,Styles,Format,Font,FontSize,Scayt',
      removeDialogTabs: 'link:advanced'
    };
    // Menu Type
    const type = this.types.find(c => c.id === this.categoryService.registerForm.get('type').value);
    this.categoryService.registerForm.get('type').setValue(type);

    // PROFILES
    this.itemService.getItems().subscribe((res) => {
      this.items = res;
    }, (error: any) => {
      console.error(error);
    }, () => {
      const item = _.find(this.items, (o) => o.id_rubro === this.categoryService.registerForm.get('id_item').value);
      this.categoryService.registerForm.get('id_item').setValue(item);
    });
  }

  onSubmit() {
    if (this.categoryService.registerForm.valid) {
      if (!this.categoryService.registerForm.get('id').value) {
        this.subscription = this.categoryService.store(this.categoryService.registerForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Guardado satisfactoriamente', 'success');
          this.onClose(true);
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('Client-side error');
          } else {
            console.log('Server-side error');
          }
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.subscription = this.categoryService.update(this.categoryService.registerForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  get f() { return this.categoryService.registerForm.controls; }

  onFileInput(fileInput: any, $event: any) {
    $event.preventDefault();
    fileInput.click();
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    this.categoryService.registerForm.controls.image.setValue(inputNode.files[0].name);

    this.resourcesService.upload(inputNode.files[0], 'images').subscribe((data: any) => {
      console.log(data);
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  onClose(confirmation: boolean) {
    this.categoryService.registerForm.reset();
    this.dialogRef.close(confirmation);
  }
}
