import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'tiposCampanasMailingEmailsPipe'})
export class tiposCampanasMailingEmailsPipe implements PipeTransform {
  public tipo : String;
  transform(value: any): String {
    switch(value) {
      case 1:
        this.tipo = 'Noticias del Día';
        break;
      case 2:
        this.tipo = 'Noticia última hora';
        break;
      default:
      break;
    }
    return this.tipo;
  }
}